import React, {useState, useEffect} from 'react';
import { Row, Button, Col} from 'react-bootstrap';
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';
import { dateToView } from '../../Global/funcoes.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faBan } from "@fortawesome/free-solid-svg-icons";

const EventosCanceladosView = ({ history, match }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [evento, setEvento] = useState([{}]);
  
  async function getDados() {
    const status = ['C']; //cancelados
    const ret = await requisitarAPI(() =>  api.get(`${match.params.tipo}/agenda/${status}/${match.params.id}`),'Falha ao obter dados do servidor');
    setRetorno(ret);
 
    if (!ret.erro) {
      setEvento(ret.response.data);
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  return (
    <>
      <MainMenu navigation={history} />
     

      <div className="titulo-pagina">Eventos Cancelados
            <Button variant="voltar" onClick={()=>{history.push(`/eventosCancelados/${match.params.tipo}`)}}>
                <FontAwesomeIcon className="icone" icon={faChevronLeft}/> Voltar para Lista de Eventos Cancelados
            </Button>

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
      </div>

      <div className="container-principal" style={{fontSize: "18px"}}>
        <Row>
          <Col>
            Pessoa: <strong>{evento.pessoa && evento.pessoa.nome}</strong>
          </Col>
          <Col>
           Data Inicio: <strong>{dateToView(evento.data_inicio)}</strong>
          </Col>
          <Col>
            Data Fim: <strong>{dateToView(evento.data_fim)}</strong>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <FontAwesomeIcon style={{height:"15px"}} icon={faBan}/> Motivo Cancelamento: <strong style={{color: "red"}}>{evento.motivo_cancelamento}</strong>
          </Col>
            
        </Row>
      </div>
    </>
  )
}

export default EventosCanceladosView;