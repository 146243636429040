import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addUserLogado: ["user"],
  removeUserLogado: []
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = {};

const add = (state = INITIAL_STATE, action) => {
  return action.user;
}

const remove = (state = INITIAL_STATE, action) => {
  return INITIAL_STATE;
}

export default createReducer(INITIAL_STATE, {
  [Types.ADD_USER_LOGADO]: add,
  [Types.REMOVE_USER_LOGADO]: remove
});
