import React, {useState, useEffect} from 'react';
import { Row} from 'react-bootstrap';
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import Listagem from '../../components/Listagens';
import {colunaEventosCancelados} from '../../components/Listagens/colunas';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const EventosCancelados = ({ history, match}) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [eventos, setEventos] = useState([{}]);
  const tipo = match.params.tipo;
  
  async function getDados() {
    const status = ['C']; //cancelados
    
    const ret = await requisitarAPI(() =>  api.get(`${tipo}/agenda/${status}`),'Falha ao obter dados do servidor');
    setRetorno(ret);
    
    if (!ret.erro) {
      setEventos(ret.response.data);
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  return (
    <>
      <MainMenu navigation={history} />
     

      <div className="titulo-pagina">Eventos Cancelados
      <a href="/ajuda" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem 
            dados={eventos} 
            cols={colunaEventosCancelados} 
            botaoExtra={{title:"Visualizar", icone:"eye", page:`eventosCancelados/view/${tipo}`}}
            noEdit
            noCancel
            history={history}
            />
        </Row>
      </div>
    </>
  )
}

export default EventosCancelados;