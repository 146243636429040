import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addNotas: ["notas"],
  addNota: ["nota"],
  editNota: ["id","nota"],
  removeNota: ["id"],

  addItem:["nota", "item"],
  editItem:["nota", "item"],
  removeItem:["id_nota", "id_item"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addNotas = (state = INITIAL_STATE, action) => action.notas;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.nota
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.nota;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) => state.filter(item => item.id !== action.id);

const addItem = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.nota.id){
      item.itens.push(action.item);
      return item;
    } else {
      return item;
    }
  })
);

const editItem = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.nota.id){
      item.itens = item.itens.map(evo => {
        if (evo.id == action.item.id) {
          return action.item;
        } else {
         return evo;
        }
      })

      return item;
    } else {
      return item;
    }
  })
);

const removerItem = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.id_nota){
      item.itens = item.itens.filter(item => item.id !== action.id_item);
      return item;
    } else {
      return item;
    }
  })
);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_NOTAS]: addNotas,
  [Types.ADD_NOTA]: add,
  [Types.EDIT_NOTA]: edit,
  [Types.REMOVE_NOTA]: remove,
  [Types.ADD_ITEM]: addItem,
  [Types.EDIT_ITEM]: editItem,
  [Types.REMOVE_ITEM]: removerItem,
});
