import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addContas: ["conta"],
  addConta: ["conta"],
  editConta: ["id","conta"],
  removeConta: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addContas = (state = INITIAL_STATE, action) => action.conta;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.conta
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.conta;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_CONTAS]: addContas,
  [Types.ADD_CONTA]: add,
  [Types.EDIT_CONTA]: edit,
  [Types.REMOVE_CONTA]: remove
});
