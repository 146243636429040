/* eslint-disable react-hooks/exhaustive-deps */
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/pt";

import React, {useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Table, Row, Col } from 'react-bootstrap';
import translate from '../../assets/translate';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
import { Creators as EventosPilatesActions } from "../../Store/Controls/agendas/eventosPilates";
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import api from '../../services/api';
import { getPessoasForSelect, getConfig } from '../../Global/chamadasAPI';
import Select from 'react-select'
import Spinner from '../../components/Spiner';
import { dateToField } from '../../Global/funcoes';
import ModalViewAgenda from '../../pages/Agendas/modalViewAgenda.js'
import ModalAulasConcluidas from '../../pages/Agendas/modalAulasConcluidas.js'
import '../../Global/styleGeral.css';

const localizer = momentLocalizer(moment);
const dataAtual = new Date();
let url = "";

const Calendario = ({tipo, history}) => {
    const isAgendaConsultorio = (tipo === 'consultorio');    
    const isAgendaPilates = (tipo === 'pilates');   
    const [showModalView, setShowModalView] = useState(false); 
    const [showModalAulasConcluidas, setShowModalAulasConcluidas] = useState(false); 
    const [dadosClickEvent, setDadosClickEvent] = useState(""); 
    
    const [loading, setLoading] = useState(true);
    const [retorno, setRetorno] = useState({});
    const [config, setConfig] = useState({tempoagenda:"45", modelo_financeiro:"A", editando:false} );
    const dispatch = useDispatch();
    const [professoresSelect, setProfessoresSelect] = useState([]);
    const [professorFiltrado, setProfessorFiltrado] = useState(null);

    const usuarioLogado = useSelector(state => state.usuarioLogado);
    const eventosConsultorio = useSelector(state => state.eventosConsultorio); 
    const eventosPilates = useSelector(state => state.eventosPilates); 
        
    async function getProfessores() {
        let profs = await getPessoasForSelect("PF");
        profs.push({label:"TODOS", value:"T"});
        setProfessoresSelect(profs);
    }


    async function getDados() {
        let ret = {};
        const status = ['A','P','R']      
        
        if (isAgendaConsultorio) {
           url = "consultorio" 
        } else {
           url = "pilates" 
           getProfessores();
        }
            
        if (professorFiltrado !== null && professorFiltrado.value !== "T") {
            ret = await requisitarAPI(() =>  api.get(`${url}/agenda/${status}/?professor=${professorFiltrado.value}`),'Falha ao obter dados do servidor');
        } else {
            ret = await requisitarAPI(() =>  api.get(`${url}/agenda/${status}`),'Falha ao obter dados do servidor');
        }
    
        setRetorno(ret);
                
        if (!ret.erro) {
            ret.response.data.map(evento => {
                evento.data_inicio = dateToField(evento.data_inicio);
                evento.data_fim = dateToField(evento.data_fim);

                return evento;
            })
        
            if (isAgendaConsultorio) {
                dispatch(EventosConsultorioActions.addEventosConsultorio(ret.response.data))
            } else {
                dispatch(EventosPilatesActions.addEventosPilates(ret.response.data))
            }
        }     

        setLoading(false);
    }

    async function getConfiguracoes() {
        const config = await getConfig();
        setConfig(config);
    }
    
    useEffect(() => {
        getDados();
        getConfiguracoes();
    },[professorFiltrado]);

    const CardEvento = ({event}) => (  
        <div>
          <label style={{color:"#fff", textAlign:'left', fontSize:'14px', fontWeight: 'Normal', fontFamily:'Open Sans'}}> <b>{event.pessoa.nome} </b></label>   
        </div>
    )

    const eventStyleGetter = (event) => {
        let backgroundColor = '#7f8c8d';
        
        if (event.status === 'P')
          backgroundColor = '#0B8043'
        else if (event.status === 'R')  
          backgroundColor = '#ffb303';  
       
        var style = {
          backgroundColor: backgroundColor,
          borderRadius: '5px',
          opacity: 0.9,
          height: '100%',
          color: '#fff',
          width: '100%',
          border: '0px',
          display: 'block',
          textAlign: 'left',
          fontSize: '16px',
          paddingTop: '3px',
          paddingBottom: '3px'
        }
        
        return {
          style: style
        }
    }

    const handleClickEvento = (props) => {
        setDadosClickEvent(props)
        setShowModalView(true);
    }

    const handleNovoEvento = ({ start, end }) => {

        if ((isAgendaConsultorio && usuarioLogado.perfil.permite_gerenciar_agenda_consultas) ||
            (isAgendaPilates && usuarioLogado.perfil.permite_gerenciar_agenda_pilates)) {
            history.push(`/${url}/agenda/cadastro/${new Date(start)}/${new Date(end)}`)}
    }

    const eventos = () => {
        if (isAgendaConsultorio) {
            return eventosConsultorio
        } else {
            return eventosPilates
        }
        
    }

    return (
        <>
        <div className="container">
            <AlertGeral retorno={retorno} />
            <Spinner loading={loading} />
        </div>
        
        <div className="container-calendario">
        
            <ModalViewAgenda tipo={tipo} history={history} dados={dadosClickEvent} show={showModalView} setShow={setShowModalView} setShowModalAulasConcluidas={setShowModalAulasConcluidas} />
            <ModalAulasConcluidas history={history} dados={dadosClickEvent} show={showModalAulasConcluidas} setShow={setShowModalAulasConcluidas} />
            {/* <ModalAulasConcluidas history={history} dados={dadosClickEvent} show={showModalAulasConcluidas} setShow={setShowModalAulasConcluidas} /> */}
            <Row>    
                <Table responsive={true}>
                
                {isAgendaPilates && (
                    <Row style={{marginTop:"5px", marginLeft:"0px"}}> 
                        <Col md={4} >
                            <Select name="id_professor" value={professorFiltrado} placeholder="Fiiltrar Professor"  options={professoresSelect}  onChange={ (option) => {setProfessorFiltrado(option)}}/>
                        </Col>
                    </Row>
                )}

                <Calendar
                    localizer={localizer}
                    defaultDate={dataAtual}
                    min={new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 6)}
                    max={new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(), 23)}
                    defaultView="week"                   
                    style={{ height: "100%", padding:"10px", fontSize:"14px" }}
                    messages={translate}
                    selectable
                    onSelectSlot={handleNovoEvento}
                    onSelectEvent={event => handleClickEvento(event)}
                    startAccessor="data_inicio"
                    endAccessor="data_fim"
                    step={config.tempoagenda}
                    timeslots={1}
                    components={{event: CardEvento}}
                    
                    eventPropGetter={(eventStyleGetter)}
                    events={eventos()}
                />
                </Table>
            </Row>
        </div>
        </>
    )
}

export default Calendario;