import React, { useState } from 'react';
import * as Yup from "yup";
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from 'formik';
import { DatePickerField } from "../../components/Form/DatePicker.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPen } from "@fortawesome/free-solid-svg-icons";

import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import api from '../../services/api';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import { CardEvolucao } from '../../Forms/Diagnosticos/evolucaoCard'
import FieldValidate from '../../components/Form/FieldValidate.js';
import Spinner from '../../components/Spiner';

const FormEvolucao = ({ diagnostico }) => {
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(false);
    const [evolucao, setEvolucao] = useState({ data_evolucao: new Date(), descricao: "", editando: false });
    const dispatch = useDispatch();

    const schema = Yup.object().shape({
        data_evolucao: Yup.date().typeError("Informe uma Data válida"),
        descricao: Yup.string().required("Informe a Descrição"),
    });

    const handleSubmit = async (values) => {
        values.id_diagnostico = diagnostico.id;
        setLoading(true);

        let ret = {};
         if (evolucao.editando) {
            ret = await requisitarAPI(() => api.put('evolucoes/' + evolucao.id, values), 'Falha ao editar evolução');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(DiagnosticoActions.editEvolucao(diagnostico, ret.response.data));                
                setEvolucao({data_evolucao: new Date(), descricao: "", editando: false });
            }

        } else {
            ret = await requisitarAPI(() => api.post('evolucoes', values), 'Falha ao cadastar evolução');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(DiagnosticoActions.addEvolucao(diagnostico, ret.response.data));
            }
        }
        
        setLoading(false);
    };

    return (
        <Formik enableReinitialize initialValues={evolucao} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
            {({ errors }) => (
                <Form>
                    <div>
                        <Row id="evolucao" style={{ paddingTop: "30px" }}>
                                <Col lg={true}>
                            <h2>Registro de Evoluções</h2>
                                </Col>
                        </Row>
                        <Row style={{ paddingTop: "15px" }}>
                            <Col lg={true} >
                                <label className="obrigatorio">Evolução*</label>
                                <FieldValidate name="descricao"  placeholder="Descreva a Evolução" className="form-control" component="textarea" type="text" errors={errors} />
                            </Col>
                            <Col lg={true}>
                                <label className="obrigatorio">Data da Evolução*</label><br />
                                <DatePickerField
                                    withPortal
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    name="data_evolucao"
                                    placeholder="Digite a data de evolução*"
                                    className="form-control"
                                />
                                <ErrorMessage name={"data_evolucao"} component="div" style={{fontSize:"16px", color:"red"}}/>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px", }}>
                            <Col lg={true}>
                                { (evolucao.editando) ?
                                    <Button variant="editar" type="submit">
                                    <FontAwesomeIcon icon={faPen} />  Editar Evolução
                                    </Button>
                                    
                                : 
                                <Button variant="salvar" type="submit">
                                <FontAwesomeIcon icon={faSave} /> Cadastrar Evolução
                                </Button>

                                } 

                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "30px", }}>
                            <AlertGeral retorno={retorno} />
                            <Spinner loading={loading} />
                        </Row>
                    </div>
                    <div style={{paddingTop:"15px", backgroundColor:"#fff2f2", paddingLeft:"15px", borderRadius:"7px"  }}>
                        <Row style={{ paddingTop: "15px", paddingLeft:"15px" }}>
                                <Col lg={true}>
                            <h2>Evoluções registradas</h2>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "15px", paddingLeft:"15px" }}>
                                <Col lg={true}>
                            {diagnostico.evolucoes.map(evolucao => (
                                <CardEvolucao key={evolucao.id} evolucao={evolucao} setEvolucao={setEvolucao} />
                            ))}
                            </Col>
                        </Row>
                    </div>

                </Form>)}
        </Formik>




    );

};

export default FormEvolucao;