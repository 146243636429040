import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addEsperas: ["esperas"],
  addEspera: ["espera"],
  editEspera: ["id","espera"],
  removeEspera: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addEsperas = (state = INITIAL_STATE, action) => action.esperas;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.espera
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.espera;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_ESPERAS]: addEsperas,
  [Types.ADD_ESPERA]: add,
  [Types.EDIT_ESPERA]: edit,
  [Types.REMOVE_ESPERA]: remove
});
