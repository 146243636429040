import React from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';

import RoutesPrivate from './private';

import Home from '../pages/Home';
import Login from '../Forms/login.js';
import Versao from '../pages/Versao';
import Ajuda from '../pages/Ajuda';

// Diagnostico
import Diagnosticos from '../pages/Diagnosticos';
import CadastroDiagnostico from '../Forms/Diagnosticos/index.js';

//Consultório
import AgendaConsultorio from '../pages/Agendas/agendaConsultorio.js';
import CadastroAgendaConsultorio from '../Forms/Agendas/Consultorio.js';
import CancelarAgendamento from '../Forms/Agendas/cancelarAgendamento.js';

//Pilates
import AgendaPilates from '../pages/Agendas/agendaPilates.js';
import CadastroAgendaPilates from '../Forms/Agendas/Pilates.js';

// Eventos Cancelados
import EventosCancelados from '../pages/EventosCancelados/index.js';
import EventosCanceladosView from '../pages/EventosCancelados/view.js';

// Lista Espera
import ListaEspera from '../pages/ListasEspera/';
import CadastroListaEspera from '../Forms/ListasEspera/';

//Pessoas
import Pessoas from '../pages/Pessoas';
import NotasFiscais from '../pages/Pessoas/notasFiscais';
import CadastroPessoa from '../Forms/Pessoas/';
import CadastroNotaFiscal from '../Forms/Pessoas/Notas/nota.js';

//Financeiro
import ContasReceber from '../pages/Financeiro/contasReceber';
import Pagamento from '../Forms/Financeiro/pagamento.js';
import FormasPagamento from '../pages/Financeiro/formasPagamento.js';
import CadastroFormaPagamento from '../Forms/Financeiro/formasPagamento.js';
import CadastroContasReceber from '../Forms/Financeiro/contasReceber.js';

//Usuário
import Usuarios from '../pages/Usuarios';
import CadastroUsuario from '../Forms/usuarios.js';

//Configurações
import Configuracoes from '../pages/Configuracoes';

//Perfil
import Perfis from '../pages/Perfis';
import CadastroPerfil from '../Forms/perfis.js';

// Mensagens usuários
import MensagensUsuarios from '../pages/MensagensUsuarios'

export default function () {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />

                {/* Pages */}
                <RoutesPrivate path="/" exact component={Home} />
                <RoutesPrivate path="/pilates/agenda" exact component={AgendaPilates} />
                <RoutesPrivate path="/consultorio/agenda" exact component={AgendaConsultorio} />
                <RoutesPrivate path="/listaEspera/:tipo" exact component={ListaEspera} />
                <RoutesPrivate path="/eventosCancelados/:tipo" exact component={EventosCancelados} />
                <RoutesPrivate path="/eventosCancelados/view/:tipo/:id" exact component={EventosCanceladosView} />
                <RoutesPrivate path="/pessoas" exact component={Pessoas} />
                <RoutesPrivate path="/pessoas/notasFiscais/:id_pessoa" exact component={NotasFiscais} />
                <RoutesPrivate path="/diagnosticos" exact component={Diagnosticos} />
                <RoutesPrivate path="/financeiro/contasReceber" exact component={ContasReceber} />
                <RoutesPrivate path="/formasPagamento" exact component={FormasPagamento} />
                <RoutesPrivate path="/usuarios" exact component={Usuarios} />
                <RoutesPrivate path="/perfis" exact component={Perfis} />
                <RoutesPrivate path="/configuracoes" exact component={Configuracoes} />
                <RoutesPrivate path="/versao" exact component={Versao} />
                <RoutesPrivate path="/ajuda" exact component={Ajuda} />

                {/* Cadastros (Forms) */}

                {/* Usuário */}
                <RoutesPrivate path="/cadastro_usuario" exact component={CadastroUsuario} />
                <RoutesPrivate path="/editar_usuario/:id" exact  component={CadastroUsuario} />

                {/* Perfil */}
                <RoutesPrivate path="/cadastro_perfil" exact component={CadastroPerfil} />
                <RoutesPrivate path="/editar_perfil/:id" exact component={CadastroPerfil} />

                {/* Agendas */}
                <RoutesPrivate path="/cancelarAgendamento/:tipo/:id" exact  component={CancelarAgendamento} />

                {/* Consultório */}
                <RoutesPrivate path="/consultorio/agenda/cadastro/:data_inicio/:data_fim" exact component={CadastroAgendaConsultorio} />
                <RoutesPrivate path="/consultorio/agenda/editar/:id" exact  component={CadastroAgendaConsultorio} />

                {/* Lista de espera */}
                <RoutesPrivate path="/listaEspera/cadastro/:tipo" exact component={CadastroListaEspera} />
                <RoutesPrivate path="/listaEspera/editar/:tipo/:id" exact component={CadastroListaEspera} />

                {/* Pilates*/}
                <RoutesPrivate path="/pilates/agenda/cadastro/:data_inicio/:data_fim" exact component={CadastroAgendaPilates} />
                <RoutesPrivate path="/pilates/agenda/cadastroGeracaoAuto/:idGeracaoAuto" exact component={CadastroAgendaPilates} />
                <RoutesPrivate path="/pilates/agenda/editar/:id" exact  component={CadastroAgendaPilates} />

                {/* Pessoa */}
                <RoutesPrivate path="/pessoas/cadastro" component={CadastroPessoa} />
                <RoutesPrivate path="/pessoas/editar/:id" component={CadastroPessoa} />
                {/* Notas Fiscais */}
                <RoutesPrivate path="/pessoas/notasFiscais/cadastro/:id_pessoa" component={CadastroNotaFiscal} />
                <RoutesPrivate path="/pessoas/notasFiscais/editar/:id_pessoa/:id_nota" component={CadastroNotaFiscal} />

                {/* Diagnósticos */}
                <RoutesPrivate path="/diagnosticos/cadastro/" exact component={CadastroDiagnostico} />
                <RoutesPrivate path="/diagnosticos/editar/:id" exact component={CadastroDiagnostico} />
                <RoutesPrivate path="/diagnosticos/cadastro/withCli/:idCliente" exact component={CadastroDiagnostico} />

                {/* Forma de Pagamento */}
                <RoutesPrivate path="/formasPagamento/cadastro" exact component={CadastroFormaPagamento} />
                <RoutesPrivate path="/formasPagamento/editar/:id" exact component={CadastroFormaPagamento} />

                {/* Contas Receber */}
                <RoutesPrivate path="/financeiro/contasReceber/cadastro" exact component={CadastroContasReceber} />
                <RoutesPrivate path="/financeiro/contasReceber/cadastro/:id_pessoa/:tipo" exact component={CadastroContasReceber} />
                <RoutesPrivate path="/financeiro/contasReceber/editar/:id" exact component={CadastroContasReceber} />
                <RoutesPrivate path="/financeiro/contasReceber/pagamento/:id" exact component={Pagamento} />

                {/* Mensagens usuários */}
                <RoutesPrivate path="/mensagensUsuarios" exact component={MensagensUsuarios} />


            </Switch>
        </BrowserRouter>
    );
}
