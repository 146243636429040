import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Formik, Form } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Creators as ListaEsperaAction } from "../../Store/Controls/listasEspera";
import '../../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import MainMenu from '../../components/MainMenu';
import api from '../../services/api';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import FieldValidate from '../../components/Form/FieldValidate.js';
import Spinner from '../../components/Spiner';
import { Number } from '../../components/Form/Number.js';

library.add(faSave, faChevronLeft);

const CadastroListaEsperaConsultorio = ({ history, match }) => {
    const [listaEspera, setListaEspera] = useState({ nome: "", telefone: "" });

    const [editForm, setEditForm] = useState(false);
    const [captionForm, setCaptionForm] = useState('Cadastro - Lista de Espera')
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);
    const [situacao, setSituacao] = useState("");

    const listasEsperas = useSelector(state => state.listaEspera);
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof match.params.id !== "undefined") {
            setCaptionForm('Edição de Lista de Espera');
            setEditForm(true);
            let lista = listasEsperas.find(item => item.id == match.params.id);
            
            if (typeof lista === "undefined") {
                history.push('/listaEspera/'+match.params.tipo);
                return;
            }

            setListaEspera(lista);
            setSituacao(lista.situacao);
        }

        setLoading(false);
    }, [match.params.id, listasEsperas]);

    const schema = Yup.object().shape({
        nome: Yup.string().required("Informe o Nome"),
        telefone: Yup.string().required("Informe o Telefone")
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        values.tipo = match.params.tipo;
        values.situacao = situacao;
        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('listaEspera/' + match.params.id, values), 'Falha ao editar Lista de Espera');
            setRetorno(ret);
            
            if (!ret.erro) {
                dispatch(ListaEsperaAction.editEspera(match.params.id, ret.response.data));
            }
            
        } else {
            
            ret = await requisitarAPI(() => api.post('listaEspera/', values), 'Falha ao cadastar lista de espera');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(ListaEsperaAction.addEspera(ret.response.data));
            }
        }

        setLoading(false);

        if (!ret.erro) {
            history.push('/listaEspera/'+values.tipo)
        }

    };

    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina"> {captionForm}
                {situacao !== "" && (
                    <label style={{color: situacao === "A" ? "green" : "red", marginLeft:"20px", fontSize:"20px"}}>
                        {situacao === "A" ? "Agendado" : "Cancelado"}
                    </label>
                )}

                <Button variant="voltar" onClick={() => { history.push(`/listaEspera/${match.params.tipo}`) }}>
                    <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Lista de Espera
            </Button>
            </div>

            <div className="container-principal">
                <AlertGeral retorno={retorno} />
                <br />
                <Formik enableReinitialize initialValues={listaEspera} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <label className="obrigatorio">Nome*</label>
                                    <FieldValidate name="nome" placeholder="Digite o Nome do Perfil" className="form-control" type="text" errors={errors} />
                                </Col>
                                <Col>
                                    <label className="obrigatorio">Telefone*</label>
                                    <FieldValidate name="telefone" component={Number} format="(##) ####-####" mask="_" placeholder="Digite o telefone" className="form-control" errors={errors} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <label>Observações</label>
                                    <FieldValidate name="observacoes" placeholder="Digite as observações" className="form-control" component="textarea" type="text" errors={errors} />
                                </Col>
                            </Row><br />
                            <br />
                            
                            <Row>
                                <Col md={3}>
                                    <Button variant="primary" onClick={()=>setSituacao('A')}>
                                        Agendado
                                    </Button>
                                
                                    <Button style={{marginLeft:"10px"}} variant="danger" onClick={()=>setSituacao('C')}>
                                        Cancelado
                                    </Button>
                                </Col>
                                {situacao === "C" && (
                                    <Col md={7}>
                                        <label style={{color:"red"}}>Motivo Cancelamento</label>
                                        <FieldValidate name="motivo_cancelamento" placeholder="Digite o motivo do cancelamento" component="textarea" type="text" errors={errors} />
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <AlertGeral retorno={retorno} />
                                    <Spinner loading={loading} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                <Col className="text-center">
                                    <Button variant="salvar" type="submit" >
                                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default CadastroListaEsperaConsultorio;
