import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../Store/Controls/usuarios";
import api from '../services/api';
import '../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../components/AlertGeral';
import MainMenu from '../components/MainMenu';
import { SelectComponent } from '../components/Form/Select.js';
import FieldValidate from '../components/Form/FieldValidate.js';
import Spinner from '../components/Spiner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const CadastroUsuario = ({ history, match }) => {
    const [editForm, setEditForm] = useState(false);
    const [user, setUser] = useState({ nome: "", acesso: "", senha: "", id_perfil: "" });
    const [perfis, setPerfis] = useState([]);
    const [captionForm, setCaptionForm] = useState('Cadastro de Usuários')
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);

    const users = useSelector(state => state.usuarios);
    const dispatch = useDispatch();

    async function getPerfis() {
        let perfisAPI = await requisitarAPI(() => api.get('Perfis'), 'Falha ao obter dados do servidor')

        if (!perfisAPI.erro) {
            let perfis = [];

            perfisAPI.response.data.forEach(perfil => {
                perfis.push({ label: perfil.nome, value: perfil.id })
            });

            setPerfis(perfis);
        }
    }

    useEffect(() => {
        setLoading(true);
        getPerfis();

        if (typeof match.params.id !== "undefined") {
            setCaptionForm('Edição de usuário');
            setEditForm(true);
            let usuario = users.find(item => item.id == match.params.id);

            if (typeof usuario === "undefined") {
                history.push('/usuarios');
                return;
            } 
            
            usuario.senha = "";
            
            setUser(usuario);
        }

        setLoading(false);
    }, [users, match.params.id]);

    const schema = Yup.object().shape({
        nome: Yup.string().required("Informe o Nome"),
        acesso: Yup.string().required("Informe o Usuário"),
        senha: Yup.string().when('edit', (e, passSchema) => editForm ? passSchema : passSchema.required("Informe a senha")),
        id_perfil: Yup.number().required("Informe o Perfil")
    });

    const handleSubmit = async (values) => {
        setLoading(true);

        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('Usuarios/' + match.params.id, values), 'Falha ao editar usuário');
            setRetorno(ret);

            if (values.senha === "") {
                values.senha = null;
            }

            if (!ret.erro) {
                dispatch(UserActions.editUser(match.params.id, ret.response.data));
            }

        } else {
            ret = await requisitarAPI(() => api.post('Usuarios', values), 'Falha ao cadastar usuário');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(UserActions.addUser(ret.response));
            }
        }

        setLoading(false);

        if (!ret.erro) {
            history.push('/usuarios')
        }
    };

    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina">{captionForm}
                <Button variant="voltar" onClick={() => { history.push('/usuarios') }}><FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para Gerenciar Usuários</Button>
            </div>

            <div className="container-principal">
                <Row>
                    <div style={{ width: "100%" }}>
                        <Formik enableReinitialize initialValues={user} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} context={editForm}>
                            {({ errors }) => (
                                <Form>
                                    <div>
                                        <div className="form-group" >
                                            <label className="obrigatorio">Nome completo*</label>
                                            <FieldValidate name="nome" placeholder="Digite o nome completo" type="text" errors={errors} />
                                        </div>

                                        <div className="form-group">
                                            <label className="obrigatorio">Nome de usuário*</label>
                                            <FieldValidate name="acesso" placeholder="Digite o nome de usuário" type="text" errors={errors} />
                                        </div>



                                        <div className="form-group">
                                            <label className="obrigatorio">{!editForm ? "Senha de usuário*" : "Alterar senha"}</label>
                                            <FieldValidate name="senha" placeholder="Digite a senha" type="password" errors={errors} />
                                        </div>

                                        <div className="form-group">
                                            <label className="obrigatorio">Perfil de usuário*</label>
                                            <FieldValidate name="id_perfil" component={SelectComponent} noClass options={perfis} errors={errors} />
                                        </div>

                                        <div className="form-group">

                                            <Row>
                                                <Col>
                                                    <AlertGeral retorno={retorno} />
                                                    <Spinner loading={loading} />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                                <Col className="text-center">
                                                    <Button variant="salvar" type="submit" >
                                                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
                                            </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Row>
            </div>
        </>
    );
}

export default CadastroUsuario;