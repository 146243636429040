import React  from 'react'
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import Calendario from "../../components/Agenda/calendario";


const AgendaConsultorio = ({history}) => {     
    return (
    <>
      <MainMenu navigation={history} />
      <div>
        <Calendario tipo='consultorio' history={history}/>
      </div>
    </>
  );
}

export default AgendaConsultorio;
