import React, { useState } from "react";
import { Button} from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch } from "react-redux";
import { Creators as UsuarioLogadoActions } from "../Store/Controls/usuarioLogado";
import '../Global/styleGeral.css';
import logo from '../assets/logo.png';
import { AlertGeral, requisitarAPI } from '../components/AlertGeral';
import api from '../services/api';
import Spinner from '../components/Spiner';

export default function Login({ history }) {
  const [loading, setLoading] = useState(false);
  const [retorno, setRetorno] = useState({});

  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    acesso: Yup.string().required("Informe o usuário"),
    senha: Yup.string().required("Informe a senha")
  });

  window.onload = function () {
    document.getElementById("usuario").focus();
  }
  
  const handleSubmit = async (values) => {
    setLoading(true);

    const ret = await requisitarAPI(() => api.post('auth/login', values), 'Falha ao logar.');
    setRetorno(ret);

    if (!ret.erro) {
      localStorage.setItem('APP_TOKEN', ret.response.data.token);
      localStorage.setItem('REFRESH_TOKEN', ret.response.data.refresh);
      
      dispatch(UsuarioLogadoActions.addUserLogado(ret.response.data.user));
      history.push('/')
    }

    setLoading(false);
  };
  return (

    <html id="bg" >
      <div className="login">
        <center><img src={logo} style={{height:'50px'}} title="Logo Overall.Cloud" alt="Logo Overall.Cloud" /></center>
        <h2 style={{ color: "#fff", textAlign: "center", margin: "25px" }}>Bem-vindo</h2>
        <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
          <Form>
            <Field className="input-login" id="usuario" name="acesso" placeholder="Usuário*" required="required" type="text" />
            <span style={{ color: "red", fontSize: "16px" }}><ErrorMessage name="acesso" /></span>

            <Field className="input-login" name="senha" placeholder="Senha*" required="required" type="password" />
            <span style={{ color: "red", fontSize: "16px" }}><ErrorMessage name="senha" /></span>

            <AlertGeral retorno={retorno} /><Spinner loading={loading} />

            <Button className="btn btn-primary btn-block btn-large" type="submit" style={{ marginBottom:"20px" }}>
               Entrar
            </Button>
            
          <p style={{color:"#7087ab", textAlign:"center", margin:"25px", fontSize:"0.6em"}}>© 2020, Overall.Cloud. Todos os direitos reservados.</p>

          </Form>
        </Formik>
      </div></html>

  );
}