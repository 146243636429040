import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

const RoutesPrivate = ({ component: Component, ...rest}) => {
    const token = localStorage.getItem('APP_TOKEN');
    const usuarioLogado = useSelector(state => state.usuarioLogado);

    return (
        <Route
            {...rest}
            render = { 
                (props ) => { 
                    if (token) {
                        const patch = props.location.pathname.toUpperCase();    
                        //tratamento para controle de permissão

                        if (patch.includes('USUARIOS') || patch.includes('PERFIS') ) {
                            if (usuarioLogado.perfil.permite_gerenciar_usuarios)
                                return <Component {...props} />   
                        } else
                        
                        if (patch.includes('PESSOAS')) {
                            if (usuarioLogado.perfil.permite_gerenciar_clientes)
                                return <Component {...props} />   
                        } else
                        
                        if (patch.includes('AGENDACONSULTORIO')) {
                            if (usuarioLogado.perfil.permite_visualizar_agenda_consultas)
                                return <Component {...props} />   
                        } else
                        
                        if (patch.includes('agendaPilates')) {
                            if (usuarioLogado.perfil.permite_visualizar_agenda_pilates)
                                return <Component {...props} />   
                        } else
                        
                        if (patch.includes('FINANCEIRO') || patch.includes('FORMASPAGAMENTO')) {
                            if (usuarioLogado.perfil.permite_gerenciar_financeiro)
                                return <Component {...props} />   
                        } else
                        
                        if (patch.includes('DIAGNOSTICOS')) {
                            if (usuarioLogado.perfil.permite_gerenciar_diagnosticos)
                                return <Component {...props} />   
                        } 

                        else {
                            return <Component {...props} /> 
                        }
                        
                    } else {
                        return <Redirect to='/login' />
                    }
                }
            }
        />
    );
}

export default RoutesPrivate;