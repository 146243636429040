import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft, faUsers, faMapMarkerAlt, faBuilding, faListAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { Creators as PessoaActions } from "../../Store/Controls/pessoas/pessoas.js";
import '../../Global/styleGeral.css';
import { DatePickerField } from "../../components/Form/DatePicker.js";
import MaskedInput from 'react-text-mask';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import { SelectComponent } from '../../components/Form/Select.js';
import api from '../../services/api';
import MainMenu from '../../components/MainMenu';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { Number } from '../../components/Form/Number.js';
import { dateToField, dateToView, calcularIdade } from '../../Global/funcoes';
import Spinner from '../../components/Spiner';

const CadastroPessoa = ({ history, match }) => {
    const [editForm, setEditForm] = useState(false);
    const [pessoa, setPessoa] = useState({ aluno:false, paciente:false, nome: '', data_nascimento : null});
    const [captionForm, setCaptionForm] = useState('Cadastro de Pessoa')
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);
    const [idade, setIdade] = useState(0);
    const [formatCPFCNPJ, setFormatCPFCNPJ] = useState('###.###.###-##');

    const pessoas = useSelector(state => state.pessoas);
    const dispatch = useDispatch();

    function getIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
        setIdade(calcularIdade(ano_aniversario, mes_aniversario, dia_aniversario));
    }

    const calcularIdadeFromInput = (data) => {
        if ((typeof data !== "undefined") && (data !== null)) {
            getIdade(data.getFullYear(), data.getMonth() + 1, data.getDate)
        }
    }

    const getUltimaConsulta = async (id) => {
        let ret = await requisitarAPI(() =>  api.get(`pessoasUltimaConsulta/${id}`),'Falha ao obter dados do servidor');
        return ret.response.data[0];        
    }
    
    const tratamentoEdicao = async () => {
        setCaptionForm('Edição de Pessoa');
            setEditForm(true);
            let pessoa = pessoas.find(item => item.id == match.params.id);

            if (typeof pessoa === "undefined") {
                history.push('/pessoas')
                return;
            }

            pessoa.data_nascimento = dateToField(pessoa.data_nascimento);
            if (pessoa.data_nascimento) {
                getIdade(
                    moment(pessoa.data_nascimento).year(),
                    moment(pessoa.data_nascimento).month(),
                    moment(pessoa.data_nascimento).day()
                );
            }

            changeValueTipoPessoa(pessoa.tipo_pessoa)
            
            // Pegar ultima aula
            if (pessoa.paciente) {
               const ultimaConsulta = await getUltimaConsulta(pessoa.id);
               pessoa.ultimaConsulta = ultimaConsulta;
            }

            setPessoa(pessoa);
    }
    
    useEffect(() => {
        if (typeof match.params.id !== "undefined") {
            tratamentoEdicao();
        }

        setLoading(false);
    }, [pessoas, match.params.id]);

    const schema = Yup.object().shape({
        nome: Yup.string().required("Informe o Nome"),
        data_nascimento: Yup.date().nullable().max(new Date(), 'Data de nascimento superior a data atual').typeError("data inválida")
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        
        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('pessoas/' + match.params.id, values), 'Falha ao editar Pessoa');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(PessoaActions.editPessoa(match.params.id, ret.response.data));
            }

        } else {
            ret = await requisitarAPI(() => api.post('pessoas', values), 'Falha ao cadastar pessoas');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(PessoaActions.addPessoa(ret.response));
            }
        }

        setLoading(false);

        if (!ret.erro) {
            history.push('/pessoas')
        }
    };

    const changeValueTipoPessoa = (value) => {
        if (value === 'F') {
            setFormatCPFCNPJ('###.###.###-##');
        } else {
            setFormatCPFCNPJ("##.###.###/####-##");
        }
    }
    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina"> {captionForm}
                {editForm && (
                    <Button variant="secundario" style={{marginLeft:"10px"}} onClick={() => { history.push('/pessoas/notasFiscais/'+pessoa.id) }}>
                        <FontAwesomeIcon className="icone" icon={faListAlt} /> Notas Fiscais
                    </Button>
                )}

                <Button variant="voltar" onClick={() => { history.push('/pessoas') }}>
                    <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para Gerenciar Pessoas
                </Button>
                <a href="/ajuda#pessoas" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

            </div>

            <div className="container-principal">
                <Row>
                    <div style={{ width: "100%" }}>
                        <Formik enableReinitialize initialValues={pessoa} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                            {({ errors }) => (
                                <Form>
                                    <Row>
                                        <Col md={4}>
                                            <h5 style={{ color: "#082044" }}> <FontAwesomeIcon icon={faUsers} /> Geral</h5>
                                        </Col>
                                        
                                        {(typeof pessoa.agendaConsultorio !== "undefined") && (pessoa.agendaConsultorio.length > 0) && (    
                                            <Col>
                                                <strong style={{color:"#8B4BF9"}}>Primeira Consulta: </strong> <label>{dateToView(pessoa.agendaConsultorio[0].data_inicio)}</label>
                                            </Col>
                                        )}

                                        {typeof pessoa.ultimaConsulta !== "undefined" && (
                                            <Col>
                                                <strong>Ultima Consulta confirmada: </strong> <label>{dateToView(pessoa.ultimaConsulta.data_inicio)}</label>
                                            </Col>
                                        )}
                                    </Row>
                                    
                                    <Row className="form-group">
                                        <Col className="form-check">
                                            <label className="obrigatorio">Tipo de Cadastro*</label> <br/>   
                                            <Field style={{ display: "inline"}} noClass name="paciente" type="checkbox" errors={errors} /><label>Paciente</label>
                                            <ErrorMessage name={"paciente"} component="div" style={{ fontSize: "16px", color: "red" }} />

                                            <Field style={{ display: "inline"}} noClass name="aluno" type="checkbox" errors={errors} /><label>Aluno</label>
                                            <ErrorMessage name={"aluno"} component="div" style={{ fontSize: "16px", color: "red" }} />
                                            
                                            <Field style={{ display: "inline"}} noClass name="professor" type="checkbox" errors={errors} /><label>Professor</label>
                                            <ErrorMessage name={"professor"} component="div" style={{ fontSize: "16px", color: "red" }} />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Tipo de Pessoa</label>
                                            <Field name="tipo_pessoa" component={SelectComponent} onChangeValue={changeValueTipoPessoa} options={[
                                                { label: "Pessoa Física", value: "F" },
                                                { label: "Pessoa Jurídica", value: "J"}]}
                                            />
                                        </Col>
                                        
                                        <Col className="form-group">
                                            <label>CPF/CNPJ</label>
                                            <Field name="cpfcnpj" component={Number} format={formatCPFCNPJ} mask="_" placeholder="Digite o CPF/CNPJ" className="form-control" type="phone" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={4} className="form-group">
                                            <label className="obrigatorio">Nome completo*</label>
                                            <FieldValidate name="nome" placeholder="Digite o nome completo*" type="text" errors={errors} />
                                        </Col>
                                        <Col sm={2} className="form-group">
                                            <label>Data de Nascimento</label><br />
                                            <DatePickerField
                                                onChangeDate={date => calcularIdadeFromInput(date)}
                                                placeholderText="Informe a data de nascimento*"
                                                name="data_nascimento"
                                                className="form-control"
                                                customInput={<MaskedInput
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    keepCharPositions={true}
                                                    guide={true}
                                                />}

                                            />
                                            <ErrorMessage name={"data_nascimento"} component="div" style={{ fontSize: "16px", color: "red" }} />
                                        </Col>
                                        <Col className="form-group">
                                            <label style={{ color: "#d2d2d2" }}>Idade</label>
                                            <Field name="idade" disabled value={idade} className="form-control" type="text" />
                                        </Col>
                                        <Col style={{ marginTop:"40px" }} className="form-group" >
                                            <Field style={{ display: "inline",}} name="exige_nfe" type="checkbox" errors={errors} /><label>Exige Nota Fiscal</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="form-group">
                                            <label>Gênero</label>
                                            <Field name="sexo" component={SelectComponent} options={[
                                                { label: "Masculino", value: "M" },
                                                { label: "Feminino", value: "F" },
                                                { label: "Não informado", value: "" }]}
                                            />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Como encontrou</label>
                                            <Field name="indicacao" component={SelectComponent} options={[
                                                { label: "Encaminhamento", value: "E" },
                                                { label: "Amigo", value: "A" },
                                                { label: "Parente", value: "P" },
                                                { label: "Indicação", value: "I" },
                                                { label: "Redes sociais", value: "R" },
                                                { label: "Pesquisa no Google", value: "G" },
                                                { label: "Site", value: "S" },
                                                { label: "TV ou Rádio", value: "T" },
                                                { label: "Outro", value: "O" }]}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="form-group">
                                            <label>E-mail</label>
                                            <Field name="email" placeholder="Digite o e-mail" className="form-control" type="text" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Telefone</label>
                                            <Field name="telefone" component={Number} format="(##) ####-####" mask="_" placeholder="Digite o telefone" className="form-control" type="phone" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Celular</label>
                                            <Field name="celular" component={Number} format="(##) # ####-####" mask="_" placeholder="Digite o Celular" className="form-control" type="phone" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Nome do contato</label>
                                            <Field name="nome_contato" placeholder="Digite o nome" className="form-control" type="text" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <h5 style={{ color: "#082044", marginTop: "40px" }}><FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço</h5>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="form-group">
                                            <label>Logradouro</label>
                                            <Field name="logradouro" placeholder="Digite o logradouro" className="form-control" type="text" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Número</label>
                                            <Field name="numero" placeholder="Digite o número" className="form-control" type="text" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Complemento</label>
                                            <Field name="complemento" placeholder="Digite o complemento" className="form-control" type="text" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="form-group">
                                            <label>Bairro</label>
                                            <Field name="bairro" placeholder="Digite o bairro" className="form-control" type="text" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Cidade</label>
                                            <Field name="cidade" placeholder="Digite a cidade" className="form-control" type="text" />
                                        </Col>

                                        <Col className="form-group">
                                            <label>Estado</label>
                                            <Field name="estado" placeholder="Digite o estado" className="form-control" type="text" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="form-group">
                                            <h5 style={{ color: "#082044", marginTop: "40px" }}><FontAwesomeIcon icon={faBuilding} /> Profissional</h5>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="form-group">
                                            <label>Profissão</label>
                                            <Field name="profissao" placeholder="Digite a Profissão" className="form-control" type="text" />
                                        </Col>

 {/*
Solicitado para remover campo Empresa - Marcos
Gustavo - 18-06-2022
				    <Col className="form-group">
                                            <label>Empresa</label>
                                            <Field name="empresa" placeholder="Digite a empresa" className="form-control" type="text" />
                                        </Col>

*/}
                                    </Row>

                                    <div style={{ width: "100%", }}>
                                        <AlertGeral retorno={retorno} />
                                        <Spinner loading={loading} />
                                    </div>

                                    <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                        <Col className="text-center">
                                            <Button variant="salvar" type="submit" >
                                                <FontAwesomeIcon className="icone" icon={faSave} /> Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>)}
                        </Formik>
                    </div>
                </Row>
            </div>
        </>
    );
}

export default CadastroPessoa;
