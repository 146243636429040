import React from 'react'
import Select from 'react-select'

export const SelectComponent = ({
  options,
  field,
  form,
  ...props
}) => {
    return (
    <Select
      {...props}
      placeholder={props.placeholder ? props.placeholder : "Selecione uma opção"}
      options={options}
      name={field.name}
      defaultValue={"Selecione uma opção"}
      value={options ? options.find(option => option.value === field.value) : ''}
      onChange={ (option) => {
        form.setFieldValue(field.name, option.value)
        if (typeof props.onChangeValue !== "undefined"){
          props.onChangeValue(option.value);
        }
      }}
      onBlur={field.onBlur}
    />
  )
}