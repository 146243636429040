import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addPerfis: ["perfis"],
  addPerfil: ["perfil"],
  editPerfil: ["id","perfil"],
  removePerfil: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addPerfis = (state = INITIAL_STATE, action) => action.perfis;

const add = (state = INITIAL_STATE, action) => [
  ...state, action.perfil
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.perfil;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) => state.filter(todo => todo.id !== action.id);


/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_PERFIS]: addPerfis,
  [Types.ADD_PERFIL]: add,
  [Types.EDIT_PERFIL]: edit,
  [Types.REMOVE_PERFIL]: remove
});
