import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addEventosConsultorio: ["eventos"],
  addEventoConsultorio: ["evento"],
  editEventoConsultorio: ["id","evento"],
  removeEventoConsultorio: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addEventosConsultorio = (state = INITIAL_STATE, action) => action.eventos;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.evento
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.evento;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_EVENTOS_CONSULTORIO]: addEventosConsultorio,
  [Types.ADD_EVENTO_CONSULTORIO]: add,
  [Types.EDIT_EVENTO_CONSULTORIO]: edit,
  [Types.REMOVE_EVENTO_CONSULTORIO]: remove
});
