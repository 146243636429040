import { createStore } from "redux";
import storage from 'redux-persist/lib/storage'
import reducers from "./Controls";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig  = {
    key: "OCAgenda",
    storage,
    whitelist: ["usuarioLogado"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export {store, persistor};
    