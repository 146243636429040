import React from 'react'
import moment from 'moment'

export function formatMoedaGrid (rowData, column)  {
    let valor = rowData[column.field];

    if ((typeof valor == "undefined") || (valor === null)) valor = 0;

    let cor = "green";
    if (valor <= 0) {
        cor = "red";
    }

    return <span style={{color: cor}}>{valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
}

export function formatDate (rowData, column) {
    const valor = moment(rowData[column.field]).format('DD/MM/YYYY');
    return <span> {valor} </span>
}
