import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";
import moment from 'moment';

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  
  registerLocale("pt-BR", pt);

  return (
    <DatePicker
      {...field}
      {...props}
      // withPortal
      dateFormat= {props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
      timeCaption="Hora"
      locale="pt-BR"
      isClearable
      timeIntervals={15}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        if ((moment(val).isValid) && (typeof props.onChangeDate !== "undefined")){
          props.onChangeDate(val);
        }
        
      }}
    />
  );
};