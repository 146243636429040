import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Creators as PerfilActions } from "../Store/Controls/perfis";
import '../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../components/AlertGeral';
import MainMenu from '../components/MainMenu';
import api from '../services/api';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import FieldValidate from '../components/Form/FieldValidate.js';
import Spinner from '../components/Spiner';

library.add(faSave, faChevronLeft);

const CadastroPerfil = ({ history, match }) => {
    const [perfil, setPerfil] = useState({ nome: "" });

    const [editForm, setEditForm] = useState(false);
    const [captionForm, setCaptionForm] = useState('Cadastro de Perfil')
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);

    const perfis = useSelector(state => state.perfis);
    const dispatch = useDispatch()

    useEffect(() => {
        if (typeof match.params.id !== "undefined") {
            setCaptionForm('Edição de Perfil');
            setEditForm(true);
            let perfil = perfis.find(item => item.id == match.params.id);

            if (typeof perfil === "undefined")
                history.push('/Perfis');
                
            setPerfil(perfil);
        }

        setLoading(false);
    }, [match.params.id, perfis]);

    const schema = Yup.object().shape({
        nome: Yup.string().required("Informe o nome")
    });

    const handleSubmit = async (values) => {
        setLoading(true);

        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('Perfis/' + match.params.id, values), 'Falha ao editar Perfil');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(PerfilActions.editPerfil(match.params.id, ret.response.data));
            }

        } else {
            ret = await requisitarAPI(() => api.post('Perfis', values), 'Falha ao cadastar perfil');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(PerfilActions.addPerfil(ret.response));
            }
        }

        setLoading(false);

        if (!ret.erro) {
            history.push('/Perfis')
        }

    };

    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina"> {captionForm}
                <Button variant="voltar" onClick={() => { history.push('/perfis') }}>
                    <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Gerenciar Perfis
            </Button>
            </div>

            <div className="container-principal">
                <AlertGeral retorno={retorno} />
                <Formik enableReinitialize initialValues={perfil} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <label className="obrigatorio">Nome*</label>
                                    <FieldValidate name="nome" placeholder="Digite o Nome do Perfil" className="form-control" type="text" errors={errors} />
                                </Col>
                            </Row><br />

                            <Row className="container">
                                <Row>
                                    <div className="form-check">
                                        <Field name="permite_visualizar_agenda_consultas" type="checkbox" errors={errors} />
                                        <label>Permite Visualizar Agenda Consultas</label>
                                        <br />

                                        <Field name="permite_visualizar_agenda_pilates" type="checkbox" errors={errors} />
                                        <label>Permite Visualizar Agenda Pilates</label>
                                        <br />

                                        <Field name="permite_gerenciar_agenda_consultas" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Agenda Consultas</label>
                                        <br />

                                        <Field name="permite_gerenciar_agenda_pilates" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Agenda Pilates</label>
                                        <br />

                                    </div>

                                    <div className="form-check">
                                        <Field name="permite_gerenciar_usuarios" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Usuários</label>
                                        <br />

                                        <Field name="permite_gerenciar_clientes" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Pessoas</label>
                                        <br />

                                        <Field name="permite_gerenciar_diagnosticos" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Diagnósicos</label>
                                        <br />

                                        <Field name="permite_gerenciar_financeiro" type="checkbox" errors={errors} />
                                        <label>Permite Gerenciar Financeiro</label>
                                    </div>
                                </Row>

                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <AlertGeral retorno={retorno} />
                                    <Spinner loading={loading} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                <Col className="text-center">
                                    <Button variant="salvar" type="submit" >
                                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
                                            </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default CadastroPerfil;
