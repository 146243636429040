import React, {useState, useEffect} from 'react';
import { Button, Row} from 'react-bootstrap';
import { Creators as PerfilActions } from "../../Store/Controls/perfis";
import { useSelector, useDispatch } from "react-redux";
import api from '../../services/api'
import MainMenu from '../../components/MainMenu';
import Listagem from '../../components/Listagens/';
import {colunasPerfil} from '../../components/Listagens/colunas';
import '../../Global/styleGeral.css';
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

library.add( faPlus );

const Perfis = ({ history }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  
  const perfis = useSelector(state => state.perfis);
  const dispatch = useDispatch()

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('Perfis'),'Falha ao obter dados do servidor')
    setRetorno(ret);
    
    if (!ret.erro) {
      dispatch(PerfilActions.addPerfis(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[perfis]);

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina"> Gerenciar Perfis
         <Button variant="principal" onClick={()=>{history.push('/cadastro_perfil')}} alt="Adicione um novo perfil" title="Adicione um novo perfil"> <FontAwesomeIcon className="icone" icon="plus"/> Novo Perfil</Button>  
         <a href="/ajuda#menu" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
       </div>

      <div className="container-principal">
        <Row>
        <Listagem dados={perfis} cols={colunasPerfil} url_edit="editar_perfil" tipo_delete="perfis" history={history}/>
        </Row>
        </div>
    </>
  )
}

export default Perfis;