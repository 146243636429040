import React from 'react';
import BarLoader from "react-spinners/BarLoader";
import '../Global/styleGeral.css';
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  width: 100%;
`;

const Spinner = ({loading}) => (
    // https://github.com/davidhu2000/react-spinners
    
    <>
    <div className="alerta">
        <BarLoader
            css={override}
            // height={}
            //width={800}
            color={"#123abc"}
            loading={loading}
        />     
    </div>
    
    </>
)

export default Spinner;