import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Row, Col, Card } from 'react-bootstrap';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from '../../components/Form/Select.js';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { useSelector, useDispatch } from "react-redux";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
import { Creators as EventosPilatesActions } from "../../Store/Controls/agendas/eventosPilates";
import '../../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import api from '../../services/api';
import MainMenu from '../../components/MainMenu';
import Spinner from '../../components/Spiner';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { dateTimeToView } from "../../Global/funcoes";
library.add(faSave, faChevronLeft);

const CancelarAgendamento = ({ history, match }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [initialValues, setinitialValues] = useState({ acao: '', data_inicio: '', data_fim: '' });
  const [evento, setEvento] = useState(null);
  const [reagendar, setReagendar] = useState(false);
  const [excluir, setExcluir] = useState(false);

  const tipo = match.params.tipo;
  const isConsultorio = (tipo === "consultorio");
  const isPilates = (tipo === "pilates");

  const eventosConsultorio = useSelector(state => state.eventosConsultorio);
  const eventosPilates = useSelector(state => state.eventosPilates);
  const dispatch = useDispatch();

  let opcoes = [{}];

  useEffect(() => {
    setLoading(true);

    if (typeof match.params.id !== "undefined") {
     
      let evnt = {};

      if (isConsultorio) {
        evnt = eventosConsultorio.find(item => item.id == match.params.id);
      } else {
        evnt = eventosPilates.find(item => item.id == match.params.id);
      }
      
      if (typeof evnt === "undefined") {
        history.push(`/${tipo}/agenda`)
        return;
      }

      setEvento(evnt);

      initialValues.data_inicio = evnt.data_inicio;
      initialValues.data_fim = evnt.data_fim;

      setinitialValues(initialValues);
    }

    opcoes.push({label: 'Excluir Evento',   value: 'E'});
    
    if (tipo === 'pilates'){
      opcoes.push({label: "Excluir todas aulas", value: 'T'});
    } 
    opcoes.push({label: "Reagendar Evento", value: 'R'});
    setLoading(false);

  }, [eventosConsultorio, eventosPilates, history, initialValues, isConsultorio, match.params.id, opcoes, tipo]);

  const schema = Yup.object().shape({
    acao: Yup.string().required("Informe o que deseja fazer com o evento"),
    data_inicio: Yup.date().nullable().when('acao', (acao, schema) => (acao === 'R') ? schema.required("Informe a data inicial") : schema),
    data_fim: Yup.date().nullable().when('acao', (acao, schema) => (acao === 'R') ? schema.required("Informe a data final") : schema),
  }, ['acao', 'acao']);

  const handleSubmit = async (values) => {
    setLoading(true);

    let ret = {};
    if (values.data_inicio > values.data_fim) {
      retorno.erro = true;
      retorno.titleErro = 'Erro de validação';
      retorno.descriptErro = 'Data inicial superior a data final';
    }

    if (values.acao === 'E') {
      evento.status = 'C';
      evento.motivo_cancelamento = values.motivo_cancelamento;

      const ret = await requisitarAPI(() => api.put(`${tipo}/agenda/${match.params.id}`, evento), 'Falha ao excluir agenda');
      setRetorno(ret);

      if (!ret.erro) {
        if (isConsultorio) {
          dispatch(EventosConsultorioActions.removeEventoConsultorio(match.params.id, values));
        } else if (isPilates) {
          dispatch(EventosPilatesActions.removeEventoPilates(match.params.id, values));
        }
      }

    } else if (values.acao === 'T') {
      evento.motivo_cancelamento = values.motivo_cancelamento;
      const ret = await requisitarAPI(() => api.put(`pilates/deleteGrupo/${evento.id_grupo}`, evento), 'Falha ao excluir agenda');
      setRetorno(ret);
      
      await requisitarAPI(() => api.delete(`contasReceber/deleteAll/${evento.pessoa.id}`, evento), 'Falha ao excluir agenda');
    } else  {
      evento.data_inicio = values.data_inicio;
      evento.data_fim = values.data_fim;
      evento.status = 'R';

      const ret = await requisitarAPI(() => api.put(`${tipo}/agenda/${match.params.id}`, evento), 'Falha ao editar agenda');
      setRetorno(ret);

      if (!ret.erro) {
        if (isConsultorio) {
          dispatch(EventosConsultorioActions.editEventoConsultorio(match.params.id, evento));
        } else if (isPilates) {
          dispatch(EventosPilatesActions.editEventoPilates(match.params.id, evento));
        }
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.push(`/${tipo}/agenda`)
    }
  };

  const onChangeValue = (val) => {
    if (val === 'R') {
      setReagendar(true);
      setExcluir(false);
    } else {
      setReagendar(false)
      setExcluir(true);
    }
  }

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">Cancelamento Agendamento
        <Button variant="voltar" onClick={() => { history.push(`/${tipo}/agenda`) }}><FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Agenda Consultório</Button>
      </div>

      <div className="container-principal">

        {evento && (
          <Card bg="light" border="primary">
            <Card.Body>
              <Card.Title className="d-flex justify-content-left" style={{ color: "#082044" }}><h4><b>{evento.pessoa.nome}</b></h4></Card.Title>
              <Card.Subtitle className="mb-2 text-muted" style={{ marginTop: "10px", marginLeft: "10px" }}>
                <Row> <b>Data inicial:</b> <span style={{ marginLeft: "10px" }}>{dateTimeToView(evento.data_inicio)}</span> </Row>
                <Row style={{ marginTop: "10px" }}> <b>Data Final:</b> <span style={{ marginLeft: "15px" }}>{dateTimeToView(evento.data_fim)}</span> </Row>
              </Card.Subtitle>
            </Card.Body>
          </Card>
        )}
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
          {({ errors }) => (
            <Form>

              <Row>
                <Col md={4} className="form-group">
                  <label className="obrigatorio">Informe o que deseja fazer com o evento *</label>
                  <FieldValidate name="acao" onChangeValue={onChangeValue} component={SelectComponent} options={opcoes} errors={errors} noClass />
                </Col>

                {excluir && (
                  <Col className="form-group">
                    <label> Motivo da Exclusão </label>
                    <Field name="motivo_cancelamento" placeholder="(Opcional)" className="form-control" as="textarea" />
                  </Col>
                )}

                {reagendar && (
                  <>
                    <Col sm={12} md={3} className="form-group">
                      <label>Data início*</label><br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_inicio"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage name="data_inicio" component="div" style={{ fontSize: "16px", color: "red" }} />
                    </Col>

                    <Col sm={12} md={3} className="form-group">
                      <label>Data Fim*</label><br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_fim"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage name="data_fim" component="div" style={{ fontSize: "16px", color: "red" }} />
                    </Col>
                  </>
                
                )}
              </Row>

              <div style={{ width: "80%", marginLeft: "25px" }}>
                <AlertGeral retorno={retorno} />
                <Spinner loading={loading} />
              </div>

              <Row style={{ marginTop: "40px" }} className="form-group text-center">
                <Col className="text-center">
                  <Button variant="salvar" type="submit" >
                    <FontAwesomeIcon className="icone" icon="save" /> Executar
                                            </Button>
                </Col>
              </Row>

            </Form>)}
        </Formik>
      </div>
    </>
  );
}

export default CancelarAgendamento;