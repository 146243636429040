import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addEventosPilates: ["eventos"],
  addEventoPilates: ["evento"],
  editEventoPilates: ["id","evento"],
  removeEventoPilates: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addEventosPilates = (state = INITIAL_STATE, action) => action.eventos;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.evento
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.evento;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_EVENTOS_PILATES]: addEventosPilates,
  [Types.ADD_EVENTO_PILATES]: add,
  [Types.EDIT_EVENTO_PILATES]: edit,
  [Types.REMOVE_EVENTO_PILATES]: remove
});
