import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Col, Row, Alert } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { Creators as NotaActions } from "../../../Store/Controls/pessoas/notasFiscais";
import api from '../../../services/api';
import '../../../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../../../components/AlertGeral';
import MainMenu from '../../../components/MainMenu';
import FieldValidate from '../../../components/Form/FieldValidate.js';
import Spinner from '../../../components/Spiner';
import FormItemNF from './itens'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CadastroNotaFiscal = ({ history, match }) => {
    const [editForm, setEditForm] = useState(false);
    const [nota, setNota] = useState({ id:0, numero: "", itens:[] });
    const [link_nota, setLink_Nota] = useState("");    
    const [captionForm, setCaptionForm] = useState('Cadastro de Nota Fiscal')

    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);

    const notas = useSelector(state => state.notasFiscais);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);

        if (typeof match.params.id_nota !== "undefined") {
            setCaptionForm('Edição de Nota Fiscal');
            setEditForm(true);
            const nota = notas.find(item => item.id == match.params.id_nota);
            setNota(nota);
            setLink_Nota(nota.link_nota);   
        }

        setLoading(false);
    }, [notas, match.params.id_nota]);

    const schema = Yup.object().shape({
        numero: Yup.number().required("Informe o Número")
    });

    const gravarNota = async (values) => {       
        setLoading(true);
        values.link_nota = link_nota;
        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('notas/' + match.params.id_nota, values), 'Falha ao editar Nota');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(NotaActions.editNota(match.params.id_nota, ret.response.data));
            }

        } else {
            values.id_pessoa = match.params.id_pessoa;
            ret = await requisitarAPI(() => api.post('notas/', values), 'Falha ao cadastar Nota');
            setRetorno(ret);
            
            if (!ret.erro) {
                dispatch(NotaActions.addNota(ret.response.data));   
            }
        }

        if (!ret.erro) {
            match.params.id_nota = ret.response.data.id;
        }

        setLoading(false);
    };

    const tratamentoLinkNota = (val) => {
        let URL = ""
        if (!val.includes("http")) {
            URL = "http://"+val;
        } else {
            URL = val;
        }
        setLink_Nota(URL);

        return URL;
    }
    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina">{captionForm}
                <Button variant="voltar" onClick={() => { history.push('/pessoas/notasFiscais/'+match.params.id_pessoa) }}><FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Gerenciar Notas Fiscais</Button>
            </div>

            <div className="container-principal">
                {/* <Row> */}
                <div style={{ width: "100%" }}>
                    <Formik enableReinitialize initialValues={nota} onSubmit={gravarNota} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                    <Form>
                        <Row className="form-groud">
                            <Col md={3}>
                                <label className="obrigatorio">Número da Nota Fiscal*</label>
                                <FieldValidate name="numero" placeholder="Digite o Número da NFs" type="number" errors={errors} />
                            </Col>
                            
                            <Col md={4}>
                                <label>Link da Nota</label> {link_nota !== "" && (<a href={link_nota} target="_blank">Acessar</a>)}
                                <FieldValidate name="link_nota" onChange={(val) => tratamentoLinkNota(val.target.value)} placeholder="Digite o site para consulta futura da NFe" type="string" errors={errors} />
                            </Col>

                            <Col md={3}>
                                <Button style={{marginTop:"30px", width:"185px"}} variant="success" type="submit" >
                                    <FontAwesomeIcon className="icone" icon="save" /> {editForm ? "Editar" : "Gravar"}
                                </Button>
                            </Col>
                        
                        </Row>

                        <div>
                            <Col>
                                <AlertGeral retorno={retorno} />
                                <Spinner loading={loading} />
                            </Col>
                        </div>
                    </Form>)}
                    </Formik>

                    {nota.id !== 0 ?    
                        <FormItemNF nota={nota} setNota={setNota} setLoading={setLoading} />
                    : 
                        <Col md={6} style={{marginTop:"20px", textAlign:"center"}}>
                            <Alert variant="danger"> <strong>É NECESSÁRIO GRAVAR O NÚMERO DA NOTA PARA LIBERAR A INSERÇÃO DOS ITENS </strong></Alert>
                        </Col>
                    }                    
                    
                </div>
                {/* </Row> */}
            </div>
        </>
    );
}

export default CadastroNotaFiscal;