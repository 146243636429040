import React, {useState, useEffect} from 'react';
import {Button, Row} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import Listagem from '../../components/Listagens';
import {colunasDiagnostico} from '../../components/Listagens/colunas';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Diagnosticos = ({ history}) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  
  const diagnosticos = useSelector(state => state.diagnosticos);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('diagnosticos'),'Falha ao obter diagnosticos do servidor');
  
    setRetorno(ret);
    if (!ret.erro) {
      dispatch(DiagnosticoActions.addDiagnosticos(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina"> Diagnósticos
        <Button variant="principal" onClick={()=>{history.push('/diagnosticos/cadastro')}} alt="Adicione um novo diagnóstico" title="Adicione um novo diagnóstico"><FontAwesomeIcon className="icone" icon={faPlus}/> Novo Diagnóstico</Button>  
        <a href="/ajuda#diagnosticos" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
       </div>

       <div className="container-principal">
        <Row>
          <Listagem dados={diagnosticos} cols={colunasDiagnostico} url_edit="diagnosticos/editar" tipo_delete="diagnosticos" history={history}/>
        </Row>
        </div>
    </>
  )
}

export default Diagnosticos;