import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import MainMenu from '../../components/MainMenu';
import Spinner from '../../components/Spiner';
import FormDiagnostico from './diagnostico';
import FormEvolucao from './evolucao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const CadastroDiagnostico = ({ history, match }) => {
    const [editForm, setEditForm] = useState(false);
    const [diagnostico, setDiagnostico] = useState({ id_pessoa: '', queixa_principal: '', evolucoes: [], diagnosticoEVA: [] });
    const [captionForm, setCaptionForm] = useState('Cadastro de Diagnósticos')
    const [loading, setLoading] = useState(true);
    const [showTabEvolucao, setShowTabEvolucao] = useState(false);
    const [keyTab, setKeyTab] = useState("geral")

    const diagnosticos = useSelector(state => state.diagnosticos);

    useEffect(() => {
        if (typeof match.params.id !== "undefined") {            
            setCaptionForm('Edição de Diagnósticos');
            setEditForm(true);
            const diagnostico = diagnosticos.find(item => item.id == match.params.id);
            if (typeof diagnostico === "undefined")
                history.push('/diagnosticos');

            setDiagnostico(diagnostico);
            
            setShowTabEvolucao(true);
            
            if (history.location.pathname.toUpperCase().includes('CADASTRO'))
                setKeyTab("evolucao");
            
        } else if (typeof match.params.idCliente !== "undefined")  {
            diagnostico.id_pessoa = parseInt(match.params.idCliente);
            setDiagnostico(diagnostico);
        }

        setLoading(false);
    }, [diagnostico,diagnosticos,match.params.idCliente, match.params.id]);

    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina"> {captionForm} <Button variant="voltar" onClick={() => { history.push('/diagnosticos') }}>
                <FontAwesomeIcon icon={faChevronLeft} className="icone" /> Voltar para Diagnósticos
                </Button>
                <a href="/ajuda#diagnosticos" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

                <Spinner loading={loading} />
            </div>

            <div className="container-principal">
                <Tabs className="nav nav-pills flex-sm-row" style={{ width: "100%", border: "0px", padding: "1rem", borderBottom: "0px", backgroundColor: "#f1f1f1" }} activeKey={keyTab} onSelect={(k) => setKeyTab(k)}>
                    <Tab eventKey="geral" title="Geral">
                        <FormDiagnostico match={match} history={history} diagnostico={diagnostico} setDiagnostico={setDiagnostico} editForm={editForm} setEditForm={setEditForm} />
                    </Tab>

                    <Tab disabled={!showTabEvolucao} eventKey="evolucao" title="Evolução" style={{ width: "100%" }}>
                        <FormEvolucao history={history} diagnostico={diagnostico} setDiagnostico={setDiagnostico} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default CadastroDiagnostico;