import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik';
import { useDispatch } from "react-redux";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import FieldValidate from '../../components/Form/FieldValidate.js';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import api from '../../services/api';
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import { MoedaFormat } from '../../components/Form/MoedaFormat.js';
import { DatePickerField } from "../../components/Form/DatePicker.js";
import Spinner from '../../components/Spiner';

const ModalRestantePagamento = ({ show, setShow, contaReceber, history }) => {
  const [retorno, setRetorno]  = useState({});
  const [loading, setLoading]  = useState(false);
  const dispatch = useDispatch();

  const schemaModal = Yup.object().shape({
    data_vencimento: Yup.date().typeError("Informe uma data válida"),
    valor: Yup.number().required("Informe o valor")
  });

  const handleModal = async (values) => {
    setLoading(true);
    contaReceber.data_vencimento = values.data_vencimento;
    contaReceber.valor = values.valor;

    contaReceber.id = null;
    contaReceber.data_geracao = new Date();
    contaReceber.pagamento_acrescimo = null;
    contaReceber.pagamento_data = null;
    contaReceber.pagamento_desconto = null;  
    contaReceber.pagamento_id_forma_pagamento = null;  
    contaReceber.pagamento_liquido = null;  
    contaReceber.pagamento_observacao = null;  
    contaReceber.parcelas_intervalo = null;  
    contaReceber.parcelas_sequencia = null;  
    contaReceber.parcelas_total = null;  
    contaReceber.pessoa = null;

    contaReceber.parcelas_sequencia = 1;
    const ret = await requisitarAPI(() => api.post('contasReceber',contaReceber),'Falha ao cadastar Conta a Receber');
    setRetorno(ret);
    
    setLoading(false);
    
    if (!ret.erro) {
      dispatch(ContasReceberActions.addContaReceber(ret.response));
      history.push('/financeiro/contasReceber') 
    }
  };
 
    return (
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
            <Col>
              <AlertGeral retorno={retorno} />
              <Spinner loading={loading} />
            </Col>

            <Formik enableReinitialize onSubmit={handleModal} initialValues={contaReceber} validationSchema={schemaModal} validateOnChange={false} validateOnBlur={false}>
            {({ errors }) => (
            <Form>
              
              <Modal.Header closeButton>
                <Modal.Title> Gerar nova fatura com o valor restante? </Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ marginLeft: "20px" }}>
                <Row>
                  <Col>
                      <label className="obrigatorio">Data de Vencimento*</label><br />
                      <DatePickerField
                          withPortal
                          placeholderText="Informe a data de Vencimento*" 
                          name="data_vencimento" 
                          className="form-control"
                      />
                      <ErrorMessage name={"data_vencimento"} component="div" style={{fontSize:"16px", color:"red"}}/>
                  </Col>
                                              
                  <Col>
                      <label className="obrigatorio">Valor*</label>
                      <FieldValidate component={MoedaFormat}  name="valor" placeholder="Digite o Valor" errors={errors}/>
                  </Col>

                </Row>   
              </Modal.Body>

              <Modal.Footer>
                <Col style={{ display: "flex" }}>
                  <Button  style={{ width: "100%" }} variant="success" type="submit">
                    Gerar
                  </Button> 
              
                  <Button style={{ width: "100%", marginLeft: "10px" }} variant="danger" onClick={() => history.push('/financeiro/contasReceber') }>
                    Cancelar
                  </Button>            
                </Col>
                
              </Modal.Footer>
              
            </Form>
            )}
            </Formik>
        </Modal>  
    )
};

export default ModalRestantePagamento;