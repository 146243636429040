import React from 'react';
import {Card, Button} from 'react-bootstrap';
import moment from "moment";    
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import api from '../../services/api';
import { requisitarAPI } from '../../components/AlertGeral';

export function CardMensagem ({dados , mensagens, setMensagens, setRetorno, setLoading}) {

    const marcarLida = async () => {
        setLoading(true);
        dados.lida = true;
        let ret = await requisitarAPI(() => api.put('mensagensUsuarios/'+dados.id, dados), 'Falha ao obter dados do servidor')
        setRetorno(ret);

        const new_mensagens = mensagens.filter(item => item.id !== dados.id);
        setMensagens(new_mensagens);
        setLoading(false);
    }

    return (
        <Card style={{backgroundColor:"#e6edff"}}>
            <Card.Body>
                <Card.Title>
                    <strong>{dados.usuarioOrigem.nome}</strong> 
                    <div style={{float:"right"}}>
                        {moment(dados.data).format('DD/MM/YYYY - HH:mm')}
                    </div>
                </Card.Title>
                <Card.Text>{dados.mensagem}</Card.Text>
                        <Button type="button" variant="editar-listagem"  onClick={()=>{marcarLida()}} ><FontAwesomeIcon icon={faEye}/> Marcar como Lida</Button>
            </Card.Body>
        </Card>
    );
}
