import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faExclamationCircle, faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";

export function statusContasReceber (rowData, column) {
    const data_vencimento = new Date(rowData['data_vencimento']);
    const data_atual = new Date();
    const status = rowData['status'];

    if (status === "P") 
        return <FontAwesomeIcon style={{color:"green"}} icon={faDollarSign}/> 
    
    if (data_vencimento <= data_atual)
        return <FontAwesomeIcon style={{color:"red"}} icon={faExclamationCircle}/>
}

export function parcelasContasReceber (rowData, column) {
    const parcela = rowData['parcelas_sequencia'];
    const parcela_total = rowData['parcelas_total'];

    if (parcela_total > 0)
        return <span>{parcela +'/'+ parcela_total}</span>
    else
        return <span>1/1</span>
}

//Pessoas
export function TipoCadastroPessoa (rowData, column) {
    let tipoStr = '';
    if (rowData['aluno'])  
        tipoStr = "Aluno";
    
    if (rowData['paciente']){
        if (tipoStr !== '')
            tipoStr = tipoStr + ",  Paciente";
        else
            tipoStr = "Paciente";
            
    }
     
    if (rowData['professor']){
        if (tipoStr !== '')
            tipoStr = tipoStr + ", Professor";
        else
            tipoStr = "Professor";
            
    }
    return <span>{tipoStr}</span>
}

export function situacaoListaEspera (rowData, column) {
    const situacao = rowData['situacao'];

    if (situacao === "A") 
        return <FontAwesomeIcon style={{color:"green"}} icon={faThumbsUp}/> 
    
    if (situacao === "C") 
        return <FontAwesomeIcon style={{color:"red"}} icon={faThumbsDown}/>
}
