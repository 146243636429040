import React, {useState, useEffect} from 'react';
import { Button, Row} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../../Store/Controls/usuarios";
import MainMenu from '../../components/MainMenu/';
import '../../Global/styleGeral.css';
import Listagem from '../../components/Listagens/';
import {colunasUsuario} from '../../components/Listagens/colunas';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral/';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Spinner from '../../components/Spiner';

library.add( faPlus, faUsers);

const Usuarios = ({ history}) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  
  const users = useSelector(state => state.usuarios);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('Usuarios'),'Falha ao obter dados do servidor')
    setRetorno(ret);
    
    if (!ret.erro) {
      dispatch(UserActions.addUsers(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina"> Gerenciar Usuários
          <Button variant="principal" onClick={()=>{history.push('/cadastro_usuario')}} alt="Adicione um novo usuário" title="Adicione um novo usuário">
            <FontAwesomeIcon className="icone" icon="plus"/> Novo Usuário
          </Button>  
          <a href="/ajuda#menu" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
       </div>

       <div className="container-principal">
        <Row>
          <Listagem dados={users} cols={colunasUsuario} url_edit="editar_usuario" tipo_delete="usuarios" history={history}/>
        </Row>
        </div>

    </>
  )
}

export default Usuarios;