import React, {useState, useEffect} from 'react';
import { Button, Row} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Creators as PessoaActions } from "../../Store/Controls/pessoas/pessoas";
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import Listagem from '../../components/Listagens';
import {colunasPessoa} from '../../components/Listagens/colunas';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Pessoas = ({ history}) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  
  const pessoas = useSelector(state => state.pessoas);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('pessoas',{ params: { tipo: 'T', listarConsultas:true } }),'Falha ao obter dados do servidor')
    setRetorno(ret);
    
    if (!ret.erro) {
      dispatch(PessoaActions.addPessoas(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  return (
    <>
      <MainMenu navigation={history} />     

      <div className="titulo-pagina">Pessoas
          <Button variant="principal" onClick={()=>{history.push('/pessoas/cadastro')}} alt="Adicione uma nova pessoa" title="Adicione uma nova pessoa"> <FontAwesomeIcon className="icone" icon={faPlus}/> Nova Pessoa</Button>  
          <a href="/ajuda#pessoas" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem dados={pessoas} cols={colunasPessoa} url_edit="pessoas/editar" tipo_delete="pessoas" history={history}/>
        </Row>
      </div>
    </>
  )
}

export default Pessoas;