import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { SelectComponent } from '../../components/Form/Select.js';
import { DatePickerField } from "../../components/Form/DatePicker.js";

const ModalAddDiasPilates = ({ listaDiaSel, listaDiasSemana, setListaDiasSemana,editListaDias, countIDDiaSemana, setCountIDDiaSemana,  show, setShow }) => {
  const [captionModal, setCaptionModal] = useState("")

  useEffect(() => {   
    setCaptionModal("Adicionar Aula da Semana");
  }, []);

  const schemaModal = Yup.object().shape({
    dia: Yup.number().required("Informe o dia").typeError("Informe o dia"),
    horario: Yup.date().required("Informe o horário").typeError("Informe o horário")
});

  const handleModal = async (values) => {
    if (editListaDias) {
      const novaLista = listaDiasSemana.map( (item) => {
        if (item.id === listaDiaSel.id) {
          return values;
        } else {
          return item;
        }
      })

      setListaDiasSemana(novaLista);
    } else {
      countIDDiaSemana = countIDDiaSemana + 1;
      setCountIDDiaSemana(countIDDiaSemana);
      
      values.id = countIDDiaSemana;
      setListaDiasSemana([...listaDiasSemana, values])
    }
    
    setShow(false)
  };
 
    return (
          <Modal show={show} onHide={() => setShow(false)} animation={true}>
            <Formik enableReinitialize initialValues={listaDiaSel} onSubmit={handleModal}  validationSchema={schemaModal} validateOnChange={false} validateOnBlur={false}>
            {({ errors }) => (
            <Form>
              
              <Modal.Header closeButton>
                <Modal.Title> {captionModal}  </Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ marginLeft: "20px" }}>
              
                  <Row className="form-group">
                    <Col  md="8">
                        <label className="obrigatorio">Dia da Semana*</label>
                        <FieldValidate name="dia" 
                          component={SelectComponent} 
                          options={[
                            {label:'Domingo', value:0},
                            {label:'Segunda', value:1},
                            {label:'Terça', value:2},
                            {label:'Quarta', value:3},
                            {label:'Quinta', value:4},
                            {label:'Sexta', value:5},
                            {label:'Sábado', value:6}
                          ]}
                          errors={errors} noClass 
                        />
                    
                    </Col>
                
                    <Col md="4">
                        <label className="obrigatorio">Horário*</label>
                        <DatePickerField
                            dateFormat="HH:mm"
                            showTimeSelect
                            showTimeSelectOnly
                            name="horario"
                            className="form-control"
                        />
                        <ErrorMessage name="horario" component="div" style={{ fontSize: "16px", color: "red" }} />
                    </Col>
                  </Row>
              </Modal.Body>

              <Modal.Footer>
                <Col style={{ display: "flex" }}>
                  <Button variant="success" style={{ width: "100%" }} type="submit">
                    {editListaDias ? "Editar" : "Adicionar"}
                  </Button>            
                </Col>
              </Modal.Footer>
              
            </Form>
            )}
            </Formik>
          </Modal>
       
    )
  
};

export default ModalAddDiasPilates;