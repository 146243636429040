import React, { useState, useEffect } from 'react';

// import "./style.css";
import { Modal, Button, Col, Alert, Row } from 'react-bootstrap';
import Spinner from '../../components/Spiner';

import { AlertGeral } from '../../components/AlertGeral';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import PanelContasReceber from '../../components/Financeiro/panelContasReceber.js'
import { gravarFinanceiro, getConfig } from '../../Global/chamadasAPI'

const ModalAulasConcluidas = ({ history, dados, show, setShow }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [dadosFinanceiro, setDadosFinanceiro] = useState({gerar_financeiro: true,  gerar_aulas: true});
  const [isGerarFinanceiro, setIsGerarFinanceiro] = useState(true);
  const [isGerarAulas, setIsGerarAulas] = useState(true);
  const [config, setConfig] = useState({tempoagenda:"45", modelo_financeiro:"A", editando:false});
  
  async function getConfiguracoes() {
    const config = await getConfig();
    setConfig(config);

    if (config.modelo_financeiro === "M") {      
      setIsGerarFinanceiro(false);
    }
  }

  useEffect(() => {

    if (typeof dados.grupo !== "undefined") {
      if (!dados.grupo.aula_experimental) {
        dadosFinanceiro.gerar_financeiro = true;
        dadosFinanceiro.descricao = "Aulas de pilates";
        dadosFinanceiro.baixar_duplicata = true;
        dadosFinanceiro.data_vencimento = new Date();  
        dadosFinanceiro.valor = dados.grupo.valor_total;
      }
      dadosFinanceiro.gerar_aulas = true;
      setDadosFinanceiro(dadosFinanceiro);
      getConfiguracoes();
    }
    
    setLoading(false)
  }, [dados, dadosFinanceiro]);

  const handleModal = async (values) => {
    setLoading(true)
    
    let erro = false;
    values.id_pessoa = dados.id_pessoa;
    
    if (values.gerar_financeiro && config.modelo_financeiro === "A") {      
      erro = await gravarFinanceiro(values, setRetorno);
    }

    if (values.gerar_aulas)
      history.push(`/pilates/agenda/cadastroGeracaoAuto/${dados.id}`)
      
    setLoading(false)   
    

    if (!erro)
      setShow(false)
  };

  if (dados !== "")
    return (
      <>
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
          <Formik enableReinitialize initialValues={dadosFinanceiro} onSubmit={handleModal}>
            {({ errors }) => (
          <Form>
          
            <Modal.Header closeButton>
                <Alert style={{marginTop:"0", width:"100%"}} variant="success">
                    {dados.grupo.aula_experimental ? 
                        <>O Aluno concluiu a <span style={{color:"green"}}> Aula Experimental</span></>  : 
                        <>O Aluno concluiu as <span style={{color:"green"}}> {dados.grupo.total_aulas} aulas</span></>} 
                </Alert>
            </Modal.Header>

            <Modal.Body>
              <Row>  
                {config.modelo_financeiro === "A" && (
                  <Col>
                    <Field 
                      name="gerar_financeiro" 
                      type="checkbox" 
                      onClick={(event) => setIsGerarFinanceiro(event.target.checked)} 
                      defaultChecked={isGerarFinanceiro}
                      style={{marginRight:"0px"}} 
                    />
                    <label><h3 style={{color:"green"}}> Gerar Financeiro</h3></label>
                    <br />      
                  </Col>   
                )}

                <Col>
                  <Field 
                    name="gerar_aulas" 
                    type="checkbox" 
                    onClick={(event) => setIsGerarAulas(event.target.checked)} 
                    defaultChecked={isGerarAulas}
                    style={{marginRight:"0px"}} 
                  />
                  <label><h3 style={{color:"green"}}> Gerar Novas Aulas</h3></label>
                  <br />      
                </Col>            

              </Row>
              {(isGerarFinanceiro) && (
                <PanelContasReceber errors={errors} />
              )}

              {isGerarAulas && (
                <Row style={{marginTop:"20px",textAlign:"center"}}>
                  <Col>
                    <label style={{color:"red"}}> <FontAwesomeIcon icon={faExclamationCircle}/> Geração de novas aulas serão definidas na próxima tela</label>
                  </Col>
                </Row>
              )}

            </Modal.Body>

            <Row style={{marginTop:"0px",marginBottom:"0px",marginLeft:"5px",marginRight:"5px"}}>
              <Col>
                  <AlertGeral retorno={retorno} />
                  <Spinner loading={loading} />
              </Col>
            </Row>   
            <Modal.Footer>                
              <Button variant="success" style={{ width: "100%" }} type="submit">
                  Gravar
              </Button>              
            </Modal.Footer>

          </Form>
          )}
          </Formik>
        </Modal>
      </>
    )
  else
    return ""  
};

export default ModalAulasConcluidas;