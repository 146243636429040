import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from '../../components/Form/Select.js';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { getPessoasForSelect } from '../../Global/chamadasAPI';
import { useSelector, useDispatch } from "react-redux";
import { Creators as EventosActions } from "../../Store/Controls/agendas/eventosConsultorio";
import '../../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import api from '../../services/api';
import MainMenu from '../../components/MainMenu';
import Spinner from '../../components/Spiner';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronLeft, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faSave, faChevronLeft, faQuestionCircle);

const CadastroAgendaConsultorio = ({ history, match }) => {
    const [captionForm, setCaptionForm] = useState('Novo evento - Agenda Consultório');
    const [pessoas, setPessoas] = useState([{}]);
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [evento, setEvento] = useState({ id_pessoa: '', data_inicio: null, data_fim: null });

    const eventos = useSelector(state => state.eventosConsultorio);
    const dispatch = useDispatch();

    async function getPessoas() {
        setPessoas(await getPessoasForSelect("P"));
    }

    useEffect(() => {
        setLoading(true);

        if ((typeof match.params.data_inicio !== "undefined") && (match.params.data_inicio !== '0')) {
            setEvento({ data_inicio: new Date(match.params.data_inicio), data_fim: new Date(match.params.data_fim) });
        }

        getPessoas();

        if (typeof match.params.id !== "undefined") {
            setCaptionForm('Editar evento - Agenda Consultório');
            setEditForm(true);
            let evnt = eventos.find(item => item.id == match.params.id);

            if (typeof evnt === "undefined") {
                history.push('/consultorio/agenda')
                return;
            }

            setEvento(evnt);
        }
        setLoading(false);
    }, [eventos, match.params.data_inicio, match.params.data_fim, match.params.id]);

    const schema = Yup.object().shape({
        id_pessoa: Yup.number().required("Informe o Pessoa"),
        data_inicio: Yup.date().typeError("Informe a data de início").when('data_fim', (data_fim, schema) => schema.max(data_fim,"Data de inicio superior a data final" )),
        data_fim: Yup.date().typeError("Informe a data final")
    });

    const handleSubmit = async (values) => {
        setLoading(true);

        let ret = {};
        if (editForm) {
            ret = await requisitarAPI(() => api.put('consultorio/agenda/' + match.params.id, values), 'Falha ao editar agenda');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(EventosActions.editEventoConsultorio(match.params.id, values));
            }

        } else {
            values.status = 'A';
            ret = await requisitarAPI(() => api.post('consultorio/agenda', values), 'Falha ao cadastar agenda consultório');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(EventosActions.addEventoConsultorio(ret));
            }
        }

        setLoading(false);

        if (!ret.erro) {
            history.push('/consultorio/agenda')
        }
    };

    return (
        <>
            <MainMenu navigation={history} />

            <div className="titulo-pagina">{captionForm}
                <Button variant="voltar" onClick={() => { history.push('/consultorio/agenda') }}><FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Agenda Consultório</Button>
                <a href="/ajuda#consultorio" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

            </div>

            <div className="container-principal">
                <Formik enableReinitialize initialValues={evento} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                        <Form>
                            <div>
                                <Row>
                                    <Col sm={12} md={3} className="form-group">
                                        <label>Data início*</label><br />
                                        <DatePickerField
                                            withPortal
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            showTimeSelect
                                            name="data_inicio"
                                            placeholder="Digite a data de evolução*"
                                            className="form-control"
                                        />
                                        <ErrorMessage name="data_inicio" component="div" style={{ fontSize: "16px", color: "red" }} />
                                    </Col>

                                    <Col sm={12} md={3} className="form-group">
                                        <label>Data Fim*</label><br />
                                        <DatePickerField
                                            withPortal
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            showTimeSelect
                                            name="data_fim"
                                            placeholder="Digite a data de evolução*"
                                            className="form-control"
                                        />
                                        <ErrorMessage name="data_fim" component="div" style={{ fontSize: "16px", color: "red" }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="form-group">
                                        <label> Pessoa *</label>
                                        <FieldValidate name="id_pessoa" placeholderText="Nome do Pessoa" component={SelectComponent} options={pessoas} errors={errors} noClass />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="form-group">
                                        <label> Observações </label>
                                        <Field name="observacao" placeholder="(Opcional)" className="form-control" as="textarea" />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                    <Col className="text-center">
                                        <Button variant="salvar" type="submit" >
                                            <FontAwesomeIcon className="icone" icon="save" /> Salvar
                                            </Button>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ marginTop: "100px", }}>
                                <AlertGeral retorno={retorno} />
                                <Spinner loading={loading} />
                            </div>
                        </Form>)}
                </Formik>
            </div>
        </>
    );
}

export default CadastroAgendaConsultorio;