import React from 'react'
import NumberFormat from 'react-number-format';


export const Number = ({
    field,
    form,
    ...props
  }) => {
      return (
        <NumberFormat
            {...field}
            {...props}
      />
    )
}
