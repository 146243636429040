import React from 'react';
import {Card, Button} from 'react-bootstrap';
import Delete from '../Delete';
import '../../Global/styleGeral.css'
import { formatMoeda } from '../../Global/funcoes'
import moment from "moment";    

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from "@fortawesome/free-solid-svg-icons";

export function CardNotasFiscais ({history, notas}) {
    return (
        <div className="card-column">
            {notas.map((nota) => (
                <Card style={{backgroundColor:"#e6edff"}}>
                    <Card.Body>                        
                        <Card.Title key={nota.id}>
                        <div className="card-column">
                            <strong>Número {nota.numero}</strong>
                        </div>
                        </Card.Title>
                        
                        <div style={{display:"flex", flexDirection:"column"}}>
                            {nota.itens.map( (item) => (
                                <span style={{flex:'1', marginTop:"5px", color:"green"}}>* {moment(item.data_evolucao).format('DD/MM/YYYY')}  - {formatMoeda(item.valor)}</span>
                            ))}   

                            <div style={{display:"flex", justifyContent:"center"}} >
                                <Button type="button" variant="editar-listagem" alt="Editar" title="Editar" onClick={()=>{history.push('/pessoas/notasFiscais/editar/'+nota.id_pessoa+'/'+nota.id)}}><FontAwesomeIcon icon={faPen}/>Editar</Button>
                                <Delete tipo="notas" alt="Apagar" title="Apagar" dados={nota}/>
                            </div>                             
                        </div>
                    </Card.Body>
                </Card>
             ))}
        </div>
    );
}
