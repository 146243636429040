import {formatMoedaGrid, formatDate} from './bodysComum';
import {statusContasReceber, parcelasContasReceber, TipoCadastroPessoa, situacaoListaEspera} from './bodysEspecificos';

// COLUNAS
export const colunasCliente = [
    {field: 'nome', header: 'Nome Completo', sortable: true},
    {field: 'cpf',  header: 'CPF/CNPJ', sortable: true},
    {field: 'fone', header: 'Celular', sortable: true},
    {field: 'email', header: 'E-mail', sortable: true},
    {field: 'endereco', header: 'Endereço', sortable: true}
]; 

export const colunasPerfil = [
    {field: 'nome', header: 'Nome',   style:{textAlign:"Center", fontWeight:"bold"},   sortable: true}
]; 

export const colunasUsuario = [
    {field: 'nome',      header: 'Nome',    style:{textAlign:"Center", fontWeight:"bold"},          sortable: true},
    {field: 'acesso',    header: 'Usuário', style:{textAlign:"Center"},                             sortable: true},
    {field: 'perfil.nome',    header: 'Perfil', style:{textAlign:"Center"},                         sortable: true},
]; 

export const colunasPessoa = [
    {field: 'nome',        header: 'Nome Completo',  style:{textAlign:"Center", fontWeight:"bold"},   sortable: true},
    {field: 'tipo',        header: 'Tipo',    body:TipoCadastroPessoa, style:{width:"200px"}},
    {field: 'cpfcnpj',     header: 'CPF/CNPJ',        style:{textAlign:"Center"},                      sortable: true},
    {field: 'logradouro',  header: 'Logradouro',     style:{textAlign:"Center"},                      sortable: true},
    {field: 'celular',     header: 'Celular',        style:{textAlign:"Center"},                      sortable: true}
]; 

export const colunasDiagnostico = [
    {field: 'pessoa.nome',    header: 'Pessoa',           style:{textAlign:"Center", fontWeight:"bold"},          sortable: true},
    {field: 'queixa_principal', header: 'Queixa Principal',   style:{textAlign:"Center"} },
];

export const colunasContas = [
    {field: 'descricao',        header: 'Descrição',                    style:{textAlign:"Center"},                     sortable: true},
    {field: 'valor',            header: 'Valor',      body:formatMoedaGrid, style:{width:"200px", textAlign:"right"}}
];

export const colunasFormasPagamento = [
    {field: 'id',               header: 'Código',             style:{width:"150px", textAlign:"Center"},         sortable: true},
    {field: 'descricao',        header: 'Descrição',          style:{textAlign:"Center", fontWeight:"bold"},     sortable: true},
];

export const colunasContasReceber = [
    {field: 'status',           header: 'Status',  body:statusContasReceber,         style:{width:"100px", textAlign:"Center"},     sortable: true},
    {field: 'parcela',           header: 'Parcelas',  body:parcelasContasReceber,         style:{width:"120px",textAlign:"Center"},     sortable: true},
    {field: 'data_vencimento',  header: 'Vencimento', body:formatDate, style:{width:"150px", textAlign:"Center"},     sortable: true},
    {field: 'pessoa.nome',    header: 'Pessoa',           style:{textAlign:"Center"},     sortable: true},
    {field: 'descricao',        header: 'Descrição',          style:{textAlign:"Center"},     sortable: true},
    {field: 'valor',            header: 'Valor',     body:formatMoedaGrid,     style:{width:"120px",textAlign:"Center"},     sortable: true},
    {field: 'pagamento_liquido',  header: 'Valor Pago',     body:formatMoedaGrid,     style:{width:"130px",textAlign:"Center"},     sortable: true},
];

export const colunasListaEsperaConsultorio = [
    {field: 'status', header: 'Situação',  body:situacaoListaEspera, style:{width:"120px", textAlign:"Center"},     sortable: true},
    {field: 'nome', header: 'Nome',   style:{textAlign:"Center"},   sortable: true},
    {field: 'telefone', header: 'Telefone',   style:{textAlign:"Center"},   sortable: true}
]; 

export const colunaEventosCancelados = [
    {field: 'pessoa.nome',    header: 'Pessoa',           style:{textAlign:"Center", fontWeight:"bold"},          sortable: true},
    {field: 'data_inicio', header: 'Data Inicio', style:{width:"150px"}, body:formatDate, sortable: true},
    {field: 'data_fim', header: 'Data Fim', style:{width:"150px"},body:formatDate, sortable: true},
    {field: 'motivo_cancelamento', header: 'Motivo',   sortable: true}
]; 


