import React, { useState } from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Button from 'react-bootstrap/Button';
import Delete from '../../pages/Delete';
import '../../Global/styleGeral.css';
import {InputText} from 'primereact/inputtext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMoneyBill, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faEdit, faMoneyBill, faEye)


export default function Listagem({header, dados, cols, url_edit, tipo_delete, history, botaoExtra, noEdit, noCancel }) {   
    const [globalFilter, setGlobalFilter] = useState("");

    let loadColumns = cols.map((col,i) => {        
        return <Column key={col.field} field={col.field} header={col.header} body={col.body} style={col.style} sortable={col.sortable} />;
    });

    function actionButtons(rowData, column) {
        return (
            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end" }}>
                {botaoExtra && <Button type="button" variant="cadastrar-listagem" alt={botaoExtra.title} title={botaoExtra.title} onClick={()=>{history.push('/'+botaoExtra.page+'/'+rowData.id)}}><FontAwesomeIcon icon={botaoExtra.icone}/><span style={{marginLeft:"10px"}}>{botaoExtra.title}</span></Button>}

                {!noEdit && (
                    <Button type="button" variant="editar-listagem" alt="Editar" title="Editar" onClick={()=>{history.push('/'+url_edit+'/'+rowData.id)}}><FontAwesomeIcon icon="edit"/> Editar</Button>
                )}

                {!noCancel && (
                    <Delete type="button" variant="apagar-listagem" tipo={tipo_delete}  alt="Apagar" title="Apagar" dados={rowData}/>
                )}
            </div>
        );
    }

    return (
        
        <div>
           <div style={{'textAlign':'left'}}>
                <FontAwesomeIcon style={{'color': "#c7c7c7"}} className="icone" icon={faSearch}/>        
                <InputText type="search" className="pesquisa" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisa" alt="Digite o que procura" title="Digite o que procura" size="50"/>
           </div>

           <div className="content-section implementation" >
                <DataTable value={dados} paginator={true} 
                    globalFilter={globalFilter}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rows={10} rowsPerPageOptions={[10,50,150]} responsive={true} reorderableColumns={true} selectionMode="single" className="lista-tabela">
                    {loadColumns}
                    <Column body={actionButtons} header="" style={{textAlign:'center', width: '300px'}}/>
                </DataTable>
            </div>
        </div>
    )
}
