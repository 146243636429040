import React from 'react'
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';


export const MoedaFormat = ({field,...props}) => {
      const { setFieldValue } = useFormikContext();
      return (
        <NumberFormat
            {...props}
            value={field.value }
            thousandSeparator={"."}
            decimalSeparator={","}
            onValueChange={
              val => {
                setFieldValue(field.name,val.floatValue); 
                if ((typeof props.onCalc !== "undefined") && (typeof props.targetFieldCalc !== "undefined")) {
                  const valor = props.onCalc(val.floatValue, field, setFieldValue);
                  setFieldValue(props.targetFieldCalc, valor)
                }
              }
            }

            prefix="R$ "
            decimalScale={2}
      />
    )
}
