import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Col, Row, Table, Card, Badge  } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import { useDispatch } from "react-redux";
import { Creators as NotaActions } from "../../../Store/Controls/pessoas/notasFiscais";
import api from '../../../services/api';
import '../../../Global/styleGeral.css';
import { dateToView, formatMoeda} from  '../../../Global/funcoes';
import FieldValidate from '../../../components/Form/FieldValidate.js';
import { DatePickerField } from "../../../components/Form/DatePicker.js";
import { MoedaFormat } from '../../../components/Form/MoedaFormat.js';
import { AlertGeral, requisitarAPI } from '../../../components/AlertGeral';
import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Delete from '../../../pages/Delete';
import Spinner from '../../../components/Spiner';

const FormItemNF = ({ nota }) => {
    const [itemSelecionado, setItemSelecionado] = useState({data: new Date(), valor: "", editando: false}); 
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    const schema = Yup.object().shape({
        data: Yup.date().required("Informe a data").typeError("Informe uma Data válida"),
        valor: Yup.number().required("Informe o Valor")
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        values.id_nota = nota.id;

        let ret = {};
        if (itemSelecionado.editando) {
            ret = await requisitarAPI(() => api.put('notasItens/' + itemSelecionado.id, values), 'Falha ao editar Item NF');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(NotaActions.editItem(nota, ret.response.data));                
            }

        } else {
            ret = await requisitarAPI(() => api.post('notasItens/', values), 'Falha ao cadastar Item NF');
            setRetorno(ret);

            if (!ret.erro) {
                dispatch(NotaActions.addItem(nota, ret.response.data));
            }
        }

        if (!ret.erro) {
            setItemSelecionado({data: new Date(), valor: 0, editando: false });
        }
        
        setLoading(false);
    };

    const btnEditClick = (item) => {
        item.editando = true;
        item.data = new Date(item.data);
        setItemSelecionado(item);
    };

    return (
        <Row style={{marginTop:"60px"}}>
            <Col md={6}>
                <strong>ITENS DA NOTA FISCAL</strong> 
                
                <Card bg="light">
                    <Card.Header>
                    <Formik enableReinitialize initialValues={itemSelecionado} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                        {({ errors }) => ( 
                        <Form>
                            {itemSelecionado.editando && (
                                <Row style={{marginBottom:"20px"}}>
                                    <Col>
                                       <h4> <Badge  variant="info">Editando...</Badge > </h4>   
                                    </Col>
                                </Row>
                            )}
                            
                        <Row>
                        <Col>
                            <label>Data*</label><br />
                            <DatePickerField
                                placeholderText="Informe a data"
                                name="data"
                                className="form-control"
                                customInput={<MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    keepCharPositions={true}
                                    guide={true}
                                />}

                            />
                            <ErrorMessage name="data" component="div" style={{ fontSize: "16px", color: "red" }} />
                        </Col>

                        <Col>
                            <label className="obrigatorio">Valor*</label>
                            <FieldValidate component={MoedaFormat}  name="valor" placeholder="Digite o Valor" errors={errors}/>
                        </Col>

                        <Col style={{marginTop:"30px"}}>
                            {itemSelecionado.editando ? 
                                <Button type="submit" variant="primary"> Editar Item</Button>
                            :
                                <Button type="submit" variant="primary"> Adicionar Item</Button>}
                            
                        </Col>
                        
                        
                        </Row>
                        </Form>)} 
                        </Formik>
                    </Card.Header>
                    
                    <Card.Body>
                        <AlertGeral retorno={retorno} />
                        <Spinner loading={loading} />

                        {nota.itens.length > 0 && (
                        <Row style={{marginTop:"10px"}}>
                        <Col>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {nota.itens.map((item) => (
                                        <tr key={item.id}>
                                        <td>{dateToView(item.data)}</td>
                                        <td>{formatMoeda(item.valor)}</td>
                                        <td>
                                            <Button type="button" variant="editar-listagem" alt="Editar" title="Editar" onClick={()=>btnEditClick(item)} ><FontAwesomeIcon icon="edit"/> Editar</Button>
                                            <Delete type="button" variant="apagar-listagem" tipo="notasItens"  alt="Apagar" title="Apagar" dados={item} />
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        </Row>
                        )}
                    </Card.Body>    
                </Card>
            </Col>
        </Row> 
    );
}

export default FormItemNF;