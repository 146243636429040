import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button, Row, Col, Card, Alert } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from '../../components/Form/Select.js';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { getPessoasForSelect, getConfig } from '../../Global/chamadasAPI';
import { timeToView, diaIntToString, dateToField } from "../../Global/funcoes"
import { useSelector, useDispatch } from "react-redux";
import { Creators as EventosActions } from "../../Store/Controls/agendas/eventosPilates";
import '../../Global/styleGeral.css';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import api from '../../services/api';
import MainMenu from '../../components/MainMenu';
import Spinner from '../../components/Spiner';
import { MoedaFormat } from '../../components/Form/MoedaFormat.js';
import ModalAddDiasPilates from './modalAddDiasPilates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';


const CadastroAgendaPilates = ({ history, match }) => {
    const [captionForm, setCaptionForm] = useState('Novo evento - Agenda Pilates');
    const [pessoas, setPessoas] = useState([{}]);
    const [professores, setProfessor] = useState([{}]);
    
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);
    const [pessoasSelect, setPessoasSelect] = useState([{}]);
    const [pessoaSelected, setPessoaSelected] = useState({gerarFinanceiro:false});
    const [isAulaExperimental, setIsAulaExperimental] = useState(false);
    const [evento, setEvento] = useState({ id_professor: '', id_pessoa: '', data_inicio: null, data_fim: null});

    const [listaDiasSemana, setListaDiasSemana] = useState([]);
    const [listaDiaSel, setListaDiaSel] = useState({id:0, dia: null, horario: null});
    const [showModalAddDias, setShowModalAddDias] = useState(false);
    const [editListaDias, setEditListadias] = useState(false);
    const [countIDDiaSemana, setCountIDDiaSemana] = useState(0);
    const [config, setConfig] = useState({tempoagenda:"45", modelo_financeiro:"A", editando:false});

    const eventos = useSelector(state => state.eventosPilates);
    const dispatch = useDispatch();
    let erroAPI = false;
    
    async function  getProfessor() {
        setPessoas(await getPessoasForSelect("A"));
        setProfessor(await getPessoasForSelect("PF"));
    }

    async function getConfiguracoes() {
        const config = await getConfig();
        setConfig(config);
    }

    async function getPessoas() {
        let pessoasAPI = [{}];            
        let pessoasSel = [{}];
        
        pessoasAPI = await requisitarAPI(() => api.get('pessoas',{ params: { tipo: 'A', listarConsultas:true } }), 'Falha ao obter pessoas do servidor')    
        
        if (!pessoasAPI.erro) {
            pessoasAPI.response.data.forEach(pessoa => {
                pessoasSel.push({ label: pessoa.nome, value: pessoa.id })
            });       
        }
            
        setPessoas(pessoasAPI.response.data);
        setPessoasSelect(pessoasSel);    
        alterarPessoaSelecionada(evento.id_pessoa, pessoasAPI.response.data)
    }

    const alterarPessoaSelecionada = (value, pessoasLoading) =>{
        let pes = pessoas;
        if (pessoasLoading) {
          pes = pessoasLoading   
        }
        
        pes.forEach(async pessoa => {
            pessoa.gerarFinanceiro = false;
            if (pessoa.id === value) {
                
                if (config.modelo_financeiro === "M"){
                    let ret = await requisitarAPI(() =>  api.get('contasReceber?id_pessoa='+pessoa.id),'Falha ao obter dados do servidor')
                    setRetorno(ret);   
                    if (ret.response.data.length === 0) {
                        pessoa.gerarFinanceiro = true;
                    }
                }
                
                setPessoaSelected(pessoa);

                return;
            }
        })
    }

    const getAgendaAPI = async (id) => {
        
        const status = ['A','P','R']  
        
        const ret = await requisitarAPI(() =>  api.get(`pilates/agenda/${status}/${id}`),'Falha ao obter agenda do servidor');
        
        if (!ret.erro) {
        
            let dados = ret.response.data;
        
            dados.duracao_aula = dados.grupo.duracao_aula;
            dados.total_aulas = dados.grupo.total_aulas;
            dados.valor_total = dados.grupo.valor_total;
            
            if (dados.grupo.aula_experimental) {
                dados.data_inicio = "";
            } else {
                const aulas = dados.grupo.aulasSemana.map( (item) => {
                    item.horario =  dateToField(item.horario); 
                    return item;
                });
                
                setListaDiasSemana(aulas);
            
                
                dados.data_inicio = foundDayWeek(dados.data_inicio, aulas)
            }
                
            dados.numero_aula = 1;    
            setEvento(dados)
        }
    }    

    const foundDayWeek = (data, listaDias) => {
            
        if (listaDias.length === 0)
            return data;

        data = moment(data).add(1, 'days'); 
        const intDiaSemana = moment(data).format('d');

        for (const item of listaDias) {
            if (intDiaSemana == item.dia) {
                const horario = timeToView(item.horario).split(':');
                const [hora, minutos ] = horario;
                const dataNova = moment(data).set({'hour': hora, 'minute': minutos});
                                    
                return dataNova.toDate();
            } 
        }

        return foundDayWeek(data, listaDias);
    }
    
    useEffect(() => {
        setLoading(true);
        getConfiguracoes();

        if ((typeof match.params.data_inicio !== "undefined") && (match.params.data_inicio !== '0')) {
            setEvento({ data_inicio: new Date(match.params.data_inicio), data_fim: new Date(match.params.data_fim) });
        } else if (typeof match.params.idGeracaoAuto !== "undefined") {
            getAgendaAPI(match.params.idGeracaoAuto);
        }

        getProfessor();
        getPessoas();

        if (typeof match.params.id !== "undefined") {
            setCaptionForm('Editar evento - Agenda Pilates');
            let evnt = eventos.find(item => item.id == match.params.id);
            
            if (typeof evnt === "undefined") {
                history.push('/pilates/agenda')
                return;
            }

            setEvento(evnt);
        }
    
        setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventos, match.params.data_inicio, match.params.data_fim, match.params.id, match.params.idGeracaoAuto, history]);

    const handleSubmit = async (values) => {
        let ret = {};  

        // FUNÇÕES ADICIONAIS SUBMIT}
        const createGrupoAulas = async () => {
            let dados = {};

            dados.aula_experimental = values.aula_experimental;
            if (dados.aula_experimental) {
                dados.total_aulas = 1;
            } else {
                dados.total_aulas = values.total_aulas;
            }
            
            dados.aulas_confirmadas = 0;
            dados.valor_total =  values.valor_total;
            dados.duracao_aula = values.duracao_aula;
            dados.numero_aula_inicio = values.numero_aula;

            ret = await requisitarAPI(() => api.post('grupoAulas', dados), 'Falha ao cadastar grupo de aulas');
            setRetorno(ret);
    
            if (!ret.erro) {                
                listaDiasSemana.map( async (item) => {
                    let dadosAulaSemana = {};
                    dadosAulaSemana.dia = item.dia;
                    dadosAulaSemana.horario = item.horario;
                    dadosAulaSemana.id_grupo = ret.response.data.id;
                    ret = await requisitarAPI(() => api.post('grupoListaAulasSemana', dadosAulaSemana), 'Falha ao cadastar lista de aulas semana');
                    setRetorno(ret);
                })

                return ret.response.data.id;
            } else {
                erroAPI = true;
            }
        }
        
        const buildEvents = async (values) => {
            
            //Grava a primeira aula
            postEvent(values,values.numero_aula)

            let dados = values;
            let data = values.data_inicio;
            
            for (let i = values.numero_aula+1; i <= values.total_aulas; i++) {
                data = await foundDayWeek(data, listaDiasSemana);
                dados.data_inicio = data;
                await postEvent(dados, i)
            }
            
        }

        const postEvent = async (dados, numero_aula) => {
            let ret = {};
       
            dados.status = 'A';
            dados.data_fim =  moment(dados.data_inicio).add(dados.duracao_aula, 'm').toDate();
            dados.pessoa = null;
            dados.grupo = null;
            dados.id = null;
            dados.createdAt = null;
            dados.updateAt = null;
            dados.numero_aula = numero_aula;

            ret = await requisitarAPI(() => api.post('pilates/agenda', dados), 'Falha ao cadastar agenda Pilates');
            setRetorno(ret);
    
            if (!ret.erro) {
                dispatch(EventosActions.addEventoPilates(ret));
            } else {
                erroAPI = true;
                throw ret;
            }
        
        }

        // INICIO SUBMIT //

        setLoading(true);       
        
        values.id_grupo = await createGrupoAulas();

        if (!erroAPI) {
            if (values.aula_experimental){
            await postEvent(values)
            } else {
                await buildEvents(values);
            }
            
            if (!erroAPI) {
                if ((!isAulaExperimental) && (pessoaSelected.gerarFinanceiro)) {
                    history.push('/financeiro/contasReceber/cadastro/'+values.id_pessoa+'/A')
                } else {
                    history.push('/pilates/agenda')
                }
                                
            }
                
        }        
        
        setLoading(false);
    };

    const addDiaSemana = () => {
        setListaDiaSel({dia: null, horario: null}); 
        setEditListadias(false);
        setShowModalAddDias(true);
    }

    const editDiaSemana = (item) => {
        setListaDiaSel(item);
        setEditListadias(true);
        setShowModalAddDias(true)
    }

    const deleteDiaSemana = (item) => {
        const novaLista = listaDiasSemana.filter( lista => item.id !== lista.id) 
        setListaDiasSemana(novaLista);
    }
    
    const schema = Yup.object().shape({
        id_pessoa: Yup.number().required("Informe o Aluno"),
        id_professor: Yup.number().required("Informe o Professor"),
        duracao_aula: Yup.number().required("Informe a duração da aula em minutos"),
        // total_aulas: Yup.number().required("Informe a quantidade total de aulas"),
        data_inicio: Yup.date().required("Informe a data").typeError("Informe a data")
    });

    return (
        <>
            <MainMenu navigation={history} />

            <ModalAddDiasPilates 
                listaDiaSel={listaDiaSel}
                editListaDias={editListaDias} 
                listaDiasSemana={listaDiasSemana} 
                setListaDiasSemana={setListaDiasSemana} 
                countIDDiaSemana={countIDDiaSemana} 
                setCountIDDiaSemana={setCountIDDiaSemana}
                show={showModalAddDias} 
                setShow={setShowModalAddDias} />

            <div className="titulo-pagina">{captionForm}
                <Button variant="voltar" onClick={() => { history.push('/pilates/agenda') }}><FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para Agenda Pilates</Button>
                <a href="/ajuda#pilates" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>  

            </div>

            <div className="container-principal">

                <Formik enableReinitialize initialValues={evento} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                        <Form>
                            
                            <div>
                                <Row className="form-check form-group"> 
                                    <Col>
                                        <Field 
                                            name="aula_experimental" 
                                            type="checkbox" 
                                            onClick={(event) => setIsAulaExperimental(event.target.checked)} 
                                            defaultChecked={isAulaExperimental}
                                            errors={errors} />
                                        <label>Aula Experimental</label>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <Col>
                                        <label className="obrigatorio">Professor*</label>
                                        <FieldValidate name="id_professor" placeholderText="Nome do Professor" component={SelectComponent} options={professores} errors={errors} noClass />
                                        
                                    </Col>
                               
                                    <Col>
                                        <label className="obrigatorio">Aluno*</label>
                                        <FieldValidate name="id_pessoa" placeholderText="Nome do Pessoa" component={SelectComponent} onChangeValue={alterarPessoaSelecionada}  options={pessoasSelect} errors={errors} noClass />
                                    </Col>
                                </Row>

                                <div>
                                    <Row className="form-group">
                                        <Col className="obrigatorio" sm={12} md={3} >
                                            <label>{isAulaExperimental ? "Data da Aula*" : "Primeira Aula*"}</label><br />
                                            <DatePickerField
                                                withPortal
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                showTimeSelect
                                                name="data_inicio"
                                                placeholder="Digite a data de evolução*"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="data_inicio" component="div" style={{ fontSize: "16px", color: "red" }} />
                                        </Col>

                                        <Col className="obrigatorio" md={3}>
                                            <label>Duração das Aulas* (Minutos)</label><br />
                                            <FieldValidate name="duracao_aula" placeholder="Duração das aulas em minutos" type="number" errors={errors} />
                                        </Col>

                                        {!isAulaExperimental && (
                                            <Col className="obrigatorio" sm={12} md={3} >
                                                <label>Quantidade total de Aulas*</label><br />
                                                <FieldValidate name="total_aulas" placeholder="Quantidade de aulas" type="number" errors={errors} />
                                            </Col>
                                        )}

                                        <Col className="obrigatorio" sm={12} md={3} >
                                            <label>Número da Aula*</label><br />
                                            <FieldValidate name="numero_aula" placeholder="Número da aula" type="number" errors={errors} />
                                        </Col>
                                    </Row>
                                    {!isAulaExperimental && (
                                        <Row className="form-group" >
                                            <Col>
                                                <Row >
                                                    <Col>
                                                        <h5>Aulas por Semana</h5>  
                                                    </Col>
                                                </Row>
                                            
                                                <Row>
                                                    <Col md={6}>
                                                        <Card>
                                                            <Card.Body>
                                                                <Card.Title> 
                                                                    <Button style={{marginLeft:"10px"}} onClick={() => addDiaSemana()}>
                                                                        <FontAwesomeIcon className="icone" icon={faPlus} /> Adicionar
                                                                    </Button>
                                                                </Card.Title>
                                                                <Card.Text>
                                                                    {listaDiasSemana.map( item => (
                                                                        <div key={item.id} style={{display:"flex", flexDirection:"row", justifyContent:"flex-end" }}>
                                                                        <Alert variant="secondary" style={{textAlign:"center", height:"50px" }}>
                                                                            <label style={{marginTop:"7px", color:"green", fontSize:"16px"}}><b>{diaIntToString(item.dia)} - {timeToView(item.horario)}</b></label>    
                                                                        </Alert>      
                                                                        
                                                                        <Button  style={{marginLeft:"10px", marginTop:"15px", width:"50px", height:"50px"}} onClick={() => editDiaSemana(item)}>
                                                                            <FontAwesomeIcon className="icone" icon={faPen} />
                                                                        </Button>
                                                                        
                                                                        <Button variant="danger" style={{marginLeft:"10px", marginTop:"15px", width:"50px", height:"50px"}} onClick={ () => deleteDiaSemana(item)}>
                                                                            <FontAwesomeIcon className="icone" icon={faTrash} />
                                                                        </Button>
                                                                    </div>
                                                                    ))}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>    
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>   
                                    )}                                            
                                </div>
                                    
                                {(!isAulaExperimental && config.modelo_financeiro === "A") && (
                                    <Row className="form-group" >
                                        <Col>
                                            <label>Valor</label>
                                            <FieldValidate component={MoedaFormat}  name="valor_total" placeholder="Digite o Valor" errors={errors}/>
                                        </Col>     
                                    </Row>                                
                                )}                               
                                
                                <Row className="form-group">
                                    <Col>
                                        <label> Observações </label>
                                        <Field name="observacao" placeholder="(Opcional)" className="form-control" as="textarea" />
                                    </Col>
                                </Row>
                                
                               <div style={{ marginTop: "100px", }}>
                                    <AlertGeral retorno={retorno} />
                                    <Spinner loading={loading} />
                                </div>

                                <Row style={{ marginTop: "40px" }} className="form-group text-center">
                                    <Col className="text-center">
                                        <Button variant="salvar" type="submit" >
                                            <FontAwesomeIcon className="icone" icon={faPlus} /> Salvar
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>)}
                </Formik>
            </div>
        </>
    );
}

export default CadastroAgendaPilates;