import React from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap';
import Delete from '../../pages/Delete/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ItemEscalaDor({listaEVA, setListaEVA, setEVASelected,}) {
    const editEVA = (item) => {
        item.editando = true;
        setEVASelected(item);
    }

    const apagarEVA = (dados) => {
        const lista = listaEVA.filter((item) => item.id !== dados.id);
    
        setListaEVA(lista);
    }

    return (
        <Row style={{marginLeft:"5px",marginTop:"10px"}}>
            <Col md="8">
                <Table striped bordered size="sm" >
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th style={{width:"100px"}}>Indice de dor</th>
                            <th style={{width:"100px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaEVA.map((item) => {
                            return (
                                <tr key={item.id}> 
                                    <td>{item.descricao}</td>
                                    <td>{item.indice}</td>    
                                    <td style={{float:"right"}}>
                                        <Button type="button"  variant="editar-listagem " onClick={()=>{editEVA(item)}}><FontAwesomeIcon icon={faPen}/></Button>
                                        <Button variant="apagar-listagem " onClick={()=>{apagarEVA(item)}}><FontAwesomeIcon icon={faTrash}/></Button>
                                    </td>
                                </tr>    
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}
