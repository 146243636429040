import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addPessoas: ["pessoa"],
  addPessoa: ["pessoa"],
  editPessoa: ["id","pessoa"],
  removePessoa: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addPessoas = (state = INITIAL_STATE, action) => action.pessoa;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.pessoa
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.pessoa;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_PESSOAS]: addPessoas,
  [Types.ADD_PESSOA]: add,
  [Types.EDIT_PESSOA]: edit,
  [Types.REMOVE_PESSOA]: remove
});
