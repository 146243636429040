/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from "react-redux";
import { Creators as ConfigActions } from "../../Store/Controls/config";
import { Formik, Form } from 'formik';
import MainMenu from '../../components/MainMenu/';
import '../../Global/styleGeral.css';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral/';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import Spinner from '../../components/Spiner';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { SelectComponent } from '../../components/Form/Select.js';

library.add( faPlus, faUsers);

const Configuracoes = ({ history}) => {
  const [retorno, setRetorno] = useState({});
  const [config, setConfig] = useState({tempoagenda:"45", modelo_financeiro:"A", editando:false});
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('config'),'Falha ao obter dados do servidor')
    setRetorno(ret);

    if (!ret.erro) {
      if (ret.response.data.length > 0){
        let conf = ret.response.data[0];
        conf.editando = true;
        setConfig(conf);

      }
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  },[]);

  const handleSubmit = async (values) => {   
    setLoading(true);

    let ret = {};
    if (values.editando){
        ret = await requisitarAPI(() => api.put('config' +'/'+ values.id,values),'Falha ao editar Configuração');
        setRetorno(ret);
        
        if (!ret.erro) {
          dispatch(ConfigActions.editConfig(values.id,values));
        }

    } else {
        ret = await requisitarAPI(() => api.post('config',values),'Falha ao cadastar Configuração');
        setRetorno(ret);
        
        if (!ret.erro) {
            dispatch(ConfigActions.addConfig(ret.response.data));
        }
    }

    setLoading(false);  
    
    if (!ret.erro){
        history.push('/')  
    }               
  };

  const schema = Yup.object().shape({
    tempoagenda: Yup.string().required("Informe o tempo da agenda")
  });


  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina"> Configurações
          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
       </div>

      <div className="container-principal">
              <Formik enableReinitialize initialValues={config} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
              {({ errors }) => (
              <Form>
                  <div>
                      <div className="form-group" >
                          <label className="obrigatorio">Tempo da Agenda (em minutos)*</label>
                          <FieldValidate name="tempoagenda" placeholder="Digite o tempo da agenda" type="text" errors={errors}/>
                      </div>

                      <div className="form-group" >
                        <label>Modelo do controle Financeiro:</label><br />
                        <FieldValidate name="modelo_financeiro" placeholderText="Informe o Modelo do controle financeiro" component={SelectComponent} options={[{label:"Fechamento em aulas",value:"A"},{label:"Mensalidade",value:"M"}]} errors={errors} noClass />
                      </div>

                      <Row>
                          <Col>
                              <AlertGeral retorno={retorno} />
                              <Spinner loading={loading} />
                          </Col>
                      </Row> 

                      <Row style={{ marginTop: "40px" }} className="form-group text-center">
                            <Col className="text-center">
                                <Button variant="salvar" type="submit" >
                                    <FontAwesomeIcon className="icone" icon="save" /> Salvar
                                </Button>
                            </Col>
                        </Row>
                  </div>           
              </Form>
              )}
          </Formik>
      </div>

       {/* <div className="container-principal">
       <Container fluid>
         <form>
            <Row xs={3} md={4} lg={6}>
            <Col>
                  <label>Tempo da Agenda (em minutos):</label>

                  <div className="form-group" >
                      <label className="obrigatorio">Descrição*</label>
                      <FieldValidate name="descricao" placeholder="Digite a descrição" type="text"/>
                  </div>
              </Col>
              <Col></Col>
            </Row>
            <br />
            <Row xs={3} md={4} lg={6}>
              <Col>
                    <label>Modelo do controle Financeiro:</label><br />
                    <input type="checkbox" checked name="fechamento-aulas" />Fechamento em aulas (padrão)<br />
                    <input type="checkbox" name="mensalidade" />Mensalidade
              </Col>
              <Col></Col>
            </Row>
            <Row style={{ marginTop: "40px" }} className="form-group text-center">
                <Col className="text-center">
                    <Button variant="salvar" type="submit" >
                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
            </Button>
                </Col>
            </Row>
          </form>
        </Container> 

        </div>*/}

    </>
  )
}

export default Configuracoes;
