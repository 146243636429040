/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// import "./style.css";
import { Modal, Button, Col, Row, Badge, Alert } from 'react-bootstrap';
import Spinner from '../../components/Spiner';
import { Formik, Form } from 'formik';
import { useDispatch } from "react-redux";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
import { Creators as EventosPilatesActions } from "../../Store/Controls/agendas/eventosPilates";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import PanelContasReceber from '../../components/Financeiro/panelContasReceber.js';
import { dateTimeToView, dateToView, formatMoeda } from '../../Global/funcoes';
import { gravarFinanceiro } from '../../Global/chamadasAPI';
import {Panel} from 'primereact/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight,faPen } from "@fortawesome/free-solid-svg-icons";
import { getPessoasForSelect } from '../../Global/chamadasAPI';
import {dateToField} from '../../Global/funcoes';
import Select from 'react-select'
import * as Yup from "yup";

import api from '../../services/api';

const ModalViewAgenda = ({ history, tipo, dados, show, setShow, setShowModalAulasConcluidas }) => {
  const [retorno, setRetorno] = useState({});
  const [captionModal, setCaptionModal] = useState("")
  const [loading, setLoading] = useState(true);
  const [gerarFinanceiro, setGerarFinanceiro] = useState(false);
  const [faturasFinanceiro, setFaturasFinanceiro] = useState([]);
  const dispatch = useDispatch();
  const isConsultorio = tipo === "consultorio";
  const isPilates = tipo === "pilates";
  const [dadosFinanceiro, setDadosFinanceiro] = useState({});
  const [editandoProfessor, setEditandoProfessor] = useState(false);
  const [editandoObservacao, setEditandoObservacao] = useState(false);
  const [novoProfessor, setNovoProfessor] = useState(null);
  const [novaObservacao, setNovaObservacao] = useState(null);
  const [professoresSelect, setProfessoresSelect] = useState([]);

  let conteudoBtnConfirma = "";

  const getGrupoAulasPilates = async () => {
    // Busca o grupo aqui na abertura do form para trazer a informação atualizada}
    const ret = await requisitarAPI(() => api.get(`grupoAulas/${dados.grupo.id}`), 'Falha ao editar grupo');
    if (!ret.erro) {
      dados.grupo = ret.response.data;
    }

  }

  const getConteudoBtnConfirma = () => {
    if (isConsultorio) {
      if (dados.status === "P") {
        return (
          <Button variant="success" style={{ width: "100%", marginLeft: "10px" }} onClick={chamadaDiagnostico}>
            Diagnóstico
          </Button>
        )
      } else {
        return (
          <Button variant="success" style={{ width: "100%", marginLeft: "10px" }} onClick={confirmarPresenca}>
            Confirmar Presença
          </Button>
        )
      }
    } else {
      if (dados.status === "P") {
        return ""
      } else {

        return  (
          <Button variant="success" style={{ width: "100%", marginLeft: "10px" }} onClick={confirmarPresenca}>
            Confirmar Presença
          </Button>
        )
      }
    }
  }

  const setDadosInicialFinanceiro = () => {
    dadosFinanceiro.descricao = "Consulta";
    dadosFinanceiro.baixar_duplicata = true;
    dadosFinanceiro.data_vencimento = new Date();  
    setDadosFinanceiro(dadosFinanceiro);
  }

  const getFaturasFinanceiro = async () => {
    let ret = await requisitarAPI(() =>  api.get('contasReceber?id_agenda_consultorio='+dados.id),'Falha ao obter dados do servidor')
    setRetorno(ret);

    setFaturasFinanceiro(ret.response.data)
  }

  async function getProfessores() {
    let profs = await getPessoasForSelect("PF");
    setProfessoresSelect(profs);
  }

  const submitAlterarProfessor = async () => {
    if ((novoProfessor != null) || (novaObservacao != null)) {
      
      let values = {};
      if (novoProfessor) {
        values = {id_professor:novoProfessor.value, id_grupo:dados.id_grupo}
      } else {
        values = {observacao:novaObservacao, id_grupo:dados.id_grupo};
      }

      
      setLoading(true);
      const ret = await requisitarAPI(() => api.put('pilates/agenda/'+dados.id,values),'Falha ao alterar agenda');
      setRetorno(ret);

      if (!ret.erro) {
        dados = ret.response.data;
        dados.data_inicio = dateToField(dados.data_inicio);
        dados.data_fim = dateToField(dados.data_fim);
        
        dispatch(EventosPilatesActions.editEventoPilates(dados.id, dados));
        setEditandoProfessor(false);
        setEditandoObservacao(false);
        setNovoProfessor(null);
        setShow(false);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (dados !== "") {
      if (isConsultorio) {
        setCaptionModal("Agendamento Consultório");
        setDadosInicialFinanceiro();
        getFaturasFinanceiro();
      } else if (isPilates) {
        setCaptionModal("Agendamento Pilates"); 

        getGrupoAulasPilates();
        getProfessores()
      }
    }

    setLoading(false);

  }, [conteudoBtnConfirma, dados, tipo]);

  const confirmarPresenca = async () => {
  
    const controlePresencaPilates = async () => {
        if (dados.numero_aula >= dados.grupo.total_aulas) {
          setShowModalAulasConcluidas(true);
        }
    }

    setLoading(true);

    dados.status = "P";

    const ret = await requisitarAPI(() => api.put(`${tipo}/agenda/${dados.id}`, dados), 'Falha ao editar agenda');
    setRetorno(ret);

    if (!ret.erro) {
      if (isConsultorio) {
        dispatch(EventosConsultorioActions.editEventoConsultorio(dados.id, dados));

      } else if (isPilates) {

        await controlePresencaPilates();
        
        dispatch(EventosPilatesActions.editEventoPilates(dados.id, dados));
      }
    }

    if (!ret.erro) {
      setShow(false);
    }

    setLoading(false);
  };

  const chamadaDiagnostico = () => {
    if (dados.pessoa.diagnosticos.length > 0) {

      async function getDiagnosticos() {
        setLoading(true);

        let ret = await requisitarAPI(() => api.get('diagnosticos'), 'Falha ao obter diagnosticos do servidor');

        setRetorno(ret);

        if (!ret.erro) {
          dispatch(DiagnosticoActions.addDiagnosticos(ret.response.data));
        }

        history.push('/diagnosticos/editar/' + dados.pessoa.diagnosticos[0].id)
        setLoading(false);
      }

      getDiagnosticos();
    }

    else
      history.push('/diagnosticos/cadastro/withCli/' + dados.id_pessoa);
  }

  const schemaFinanceiro = Yup.object().shape({
    descricao: Yup.string().required("Informe a Descricão"),
    data_vencimento: Yup.date().typeError("Informe uma data válida"),
    valor: Yup.number().required("Informe o valor"),
    parcelas_total: Yup.number().when('parcelas_intervalo', (parcelas_intervalo, schema) => (parcelas_intervalo > 0) ? schema.required("Informe o total de parcelas") : schema).typeError("informe um número válido"),
    parcelas_intervalo: Yup.number().when('parcelas_total', (parcelas_total, schema) => (parcelas_total > 0) ? schema.required("Informe o intervalo de dias") : schema).typeError("informe um número válido")
    
},['parcelas_total','parcelas_intervalo']);


  const submitGravarFinanceiro = async (values) => {
    values.id_pessoa = dados.id_pessoa;
    values.id_agenda_consultorio = dados.id;
    const erro = await gravarFinanceiro(values, setRetorno);
    if (!erro) {
      setGerarFinanceiro(false);
      getFaturasFinanceiro();
      }
    
  };

  if (dados !== "")

    return (
      <>
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title> {captionModal}  </Modal.Title>

            {dados.status !== "P" && isConsultorio && (
              <Button variant="primary" style={{ marginLeft: "10px", width: "100px" }} onClick={() => { history.push(`/${tipo}/agenda/editar/${dados.id}`)}}>
                Editar
              </Button>
            )}

          </Modal.Header>

          <Modal.Body style={{ marginLeft: "20px" }}>

          {isPilates ?
            <Row style={{ marginTop: "3px",marginRight: "15px", borderRadius:"10px", backgroundColor:"#e6edff", padding:"10px",display:"flex", justifyContent:"space-around" }}>
              <span style={{ color: "#8a8c85", fontSize:"16px" }}><strong>Número da Aula:</strong> <span style={{color:"black"}}> {dados.numero_aula}</span></span>
              <span style={{ color: "#8a8c85", fontSize:"16px" }}><strong>Total de Aulas:</strong> <span style={{color:"black"}}>{dados.grupo.total_aulas}</span></span>
              {dados.pessoa.exige_nfe && (  
              <span style={{ padding:"10px", borderRadius:"20px", backgroundColor:"green", color: "white", fontSize:"12px"}}> Exige Nota Fiscal</span>
              )}
            </Row>
          :
            dados.pessoa.exige_nfe && (  
              <div style={{ marginTop: "3px", marginBottom:"5px", display:"flex", justifyContent:"flex-end", flexDirection:"row" }}> 
                <span style={{ padding:"10px", borderRadius:"20px", backgroundColor:"green", color: "white", fontSize:"12px"}}> Exige Nota Fiscal</span>
              </div>
            )
          }
            
            <div style={{ color: "rgb(102, 179, 106)"}}> 

              <Row style={{ marginTop: "10px" }}><span style={{ color: "#082044", fontSize:"20px" }}><strong>{isPilates ? "Aluno" : "Paciente"}:</strong> <span style={{color:"gray"}}> {dados.pessoa.nome}</span></span></Row>
              {isPilates && (
                <>
                  <Row style={{ marginTop: "15px" }}><span style={{ color: "#082044", fontSize:"18px" }}><strong>Professor:</strong> <span style={{color:"gray"}}>{dados.professor.nome}</span></span>
                    <Button variant="info" size="sm" style={{ marginLeft: "15px", marginTop: "-5px",width:"35px" }} onClick={()=>setEditandoProfessor(!editandoProfessor)}> <FontAwesomeIcon className="icone" icon={faPen}/></Button>                             
                  </Row>
                  
                  {editandoProfessor && (
                    <Row>  
                      <Col className="form-group"  md={10}>
                        <Select name="id_novo_professor" value={novoProfessor} placeholder="Novo Professor"  options={professoresSelect}  onChange={ (option) => {setNovoProfessor(option)}}/>
                        <Button size="sm" style={{marginTop:"5px"}} variant="success" onClick={submitAlterarProfessor}>Salvar</Button> 
                      </Col>                    
                    </Row>
                  )}  
                </>
              )}
              
              <Row style={{ marginTop: "15px" }}> 
                <strong>Data inicial:</strong> <span style={{ marginLeft: "5px" }}>{dateTimeToView(dados.data_inicio)}</span> 
                <strong style={{ marginLeft: "15px", color:"red" }}>Data Final:</strong> <span style={{ marginLeft: "5px", color:"red" }}>{dateTimeToView(dados.data_fim)}</span> 
              </Row>              
            </div>

            <Row style={{ marginTop: "15px"}}><span style={{ color: "#082044", fontSize:"18px" }}> <strong>Observação</strong> </span>
               <Button variant="info" size="sm" style={{ marginLeft: "15px", marginTop: "-5px",width:"35px" }} onClick={()=>setEditandoObservacao(!editandoObservacao)}> <FontAwesomeIcon className="icone" icon={faPen}/></Button>                             
            </Row>

            {editandoObservacao ? <Row style={{ marginTop: "10px",marginRight: "1px" }}>
                                    <textarea className="form-control" name="observacao" placeholder={dados.observacao}  onChange={(event) => {setNovaObservacao(event.target.value)}} />
                                    <Button size="sm" style={{marginTop:"5px"}} variant="success" onClick={submitAlterarProfessor}>Salvar</Button> 
                                  </Row> :
                                  <Row><span> {dados.observacao}</span></Row>}      
                     
                <div style={{ marginTop: "10px", }}>
                  <AlertGeral retorno={retorno} />
                  <Spinner loading={loading} />
                </div>
            
            {(isConsultorio && dados.status === 'P' ) && (
              <>
                {faturasFinanceiro.length > 0 ? (

                  <Panel header="Faturas Financeiro">
                    {faturasFinanceiro.map((item) => (
                      <Row style={{marginTop:"10px"}}>
                        <Col>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} />
                          
                          <strong style={{marginLeft:"10px"}}>Vencimento:</strong> {dateToView(item.data_vencimento)}                        
                          <strong style={{marginLeft:"29px"}}>Valor:</strong> {formatMoeda(item.valor)}                          

                          {item.status === "P" && (
                             <Badge style={{marginLeft:"5px"}} variant="success">Pago</Badge>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Panel>
                ):
                  <Formik enableReinitialize initialValues={dadosFinanceiro} onSubmit={submitGravarFinanceiro} validationSchema={schemaFinanceiro} validateOnChange={false} validateOnBlur={false}>
                  {({ errors }) => (
                  <Form>
                    <Row style={{marginTop:"10px"}}>
                      <Col>
                        <Button variant="primary" onClick={() => setGerarFinanceiro(!gerarFinanceiro)}>
                          Gerar Financeiro
                        </Button>
                      </Col>
                    </Row>

                    {gerarFinanceiro && (
                      <Row style={{marginTop:"10px"}}>
                        <Col>
                          <PanelContasReceber errors={errors} />
                        </Col>
                      </Row>
                    )}
                  </Form>
                  )}  
                </Formik>
                }
              </>
            )}            
                
          </Modal.Body>

            <Modal.Footer>

              <Col style={{ display: "flex" }}>
                <Button variant="danger" style={{ width: "100%" }} onClick={() => history.push(`/cancelarAgendamento/${tipo}/${dados.id}`)}>
                  Cancelar Presença
                </Button>

                {getConteudoBtnConfirma()}    
              </Col>
            </Modal.Footer>
        </Modal>
      </>
    )
  else
    return ""  
};

export default ModalViewAgenda;