import React, { useState, useEffect } from 'react';
import ItemEscalaDor from './itemEscalaDor.js'
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import { SelectComponent } from '../../components/Form/Select.js';

export default function FieldEVA({listaEVA, setListaEVA}) {
    const [optionsEVA, setOptionsEVA] = useState([{}]);
    const [EVASelected, setEVASelected] = useState({id:0, descricao:"", indice:null, editando:false});

    async function getOptionsEVA() {
        function montaOptions() {
            let options = [];
            for (let i = 0; i <= 10; i++) {
                options.push({ label: i, value: i });
            }
            return options;
        };

        setOptionsEVA(await montaOptions());
    }

    useEffect(() => {
        getOptionsEVA();
    
    }, []);

    const handleSubmitEVA = async (values) => {
        if (EVASelected.editando) {
           const lista = listaEVA.map((item) => {
                if (item.id === EVASelected.id) {
                    return values;
                } else {
                    return item;
                }
            });
            listaEVA = lista;
            
        } else {
            if (listaEVA.length > 0) {
                values.id = listaEVA[listaEVA.length-1].id + 1;
            } else {
                values.id = 1;
            }

            listaEVA.push(values);
        }
        setListaEVA(listaEVA);
        setEVASelected({descricao:"", indice:0});
    }


    return (
        <Formik enableReinitialize initialValues={EVASelected} onSubmit={handleSubmitEVA} validateOnChange={false} validateOnBlur={false}>
            <Form>
                <div style={{ marginTop: "15px", backgroundColor:"#e6edff", padding:"20px", borderRadius: "10px" }}>
                    <h3><b>Escala Visual Analógica (EVA)</b></h3>
                    <Row style={{marginLeft:"5px"}}>
                        <Col md="4">
                            <label>Descrição</label>
                            <Field name="descricao" placeholderText="Insira a Descrição" placeholder="Insira a Descrição" className="form-control" type="text"/>
                        </Col> 

                        <div>
                            <Row>
                                <Col>
                                <label>Indice de dor </label>
                                <Field name="indice" placeholder=" " component={SelectComponent} options={optionsEVA} />
                                </Col>
                                <div md="1" style={{marginTop:"30px"}}>
                                    <Button type="submit">
                                        { (EVASelected.editando) ?    
                                            <> <FontAwesomeIcon icon={faPen} />  Editar </> 
                                        :
                                            <> <FontAwesomeIcon icon={faPlus} size="sm"/> Cadastrar</>
                                        }
                                    </Button> 
                                </div> 
                            </Row>
                        </div>                                   
                    </Row>
                               
                    {listaEVA.length > 0 && (
                        <ItemEscalaDor listaEVA={listaEVA} setListaEVA={setListaEVA} setEVASelected={setEVASelected} />
                    )}
                </div>    
            </Form>
        </Formik>
                        
    )
}
