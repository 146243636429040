/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {Row, Col, Badge, Button} from 'react-bootstrap';
import MainMenu from '../../components/MainMenu/';
import '../../Global/styleGeral.css';
import { getConfig } from '../../Global/chamadasAPI';
import { useSelector } from "react-redux";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import { CardMensagem } from '../MensagensUsuarios/cardMensagem'
import api from '../../services/api';
import Spinner from '../../components/Spiner';

export default function Home({history}) {
  const usuarioLogado = useSelector(state => state.usuarioLogado);
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [mensagensUsuarios, setMensagensUsuarios] = useState([]);

    const getMensagensUsuarios = async () => {
      let mensagensAPI = await requisitarAPI(() => api.get('mensagensUsuarios/'+usuarioLogado.id), 'Falha ao obter dados do servidor')
        setRetorno(mensagensAPI);

        if (!mensagensAPI.erro) {
          setMensagensUsuarios(mensagensAPI.response.data);
        }
    }

    useEffect(() => {
      getMensagensUsuarios();
      setLoading(false);
    },[]);

    return (
      <>
      <div>
        <MainMenu navigation={history} />

        
        <div className="titulo-pagina-principal"> Bem vindo, {usuarioLogado.nome}!
          <a href="/ajuda" alt="Clique para acessar página de ajuda" style={{ float:"right" }} title="Clique para acessar página de ajuda"><FontAwesomeIcon className="icone" icon={faQuestionCircle}/></a>
        </div>

        <div className="titulo-pagina-principal">Notificações</div>
        <div className="container-principal">

          <Row>
              <p>Área reservada para notificações.</p>
          </Row>

          <Row>
            <p><strong>Nenhuma notificação.</strong></p>
          </Row>
        </div>


        {/* <div className="titulo-pagina-principal" style={{ color:"red" }}>Faturas vencidas</div>
        <div className="container-principal">

          <Row>
              <p>Faturas Vencidas</p>
          </Row>
        <br />
          <Row>
            <p>Faturas a vencer da semana</p>
          </Row>
        </div>

        <Col>
          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
        </Col>
      */}

      </div> 

        {mensagensUsuarios.length > 0 && (
          <>
          <div className="titulo-pagina-principal">Mensagens <Badge variant="success">{mensagensUsuarios.length}</Badge> <Button style={{marginLeft:"10px"}} onClick={()=> history.push('/mensagensUsuarios')}> Ir para Mensagens </Button></div>
          <div className="container-principal">

            <div style={{marginTop:"20px"}}>
              {mensagensUsuarios.map( item => <CardMensagem dados={item} mensagens={mensagensUsuarios} setMensagens={setMensagensUsuarios} setRetorno={setRetorno} setLoading={setLoading} />)}
            </div>

          </div>
        </>
        )}





      </>
    )
  }
