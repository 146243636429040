import { combineReducers } from "redux";

import usuarios from "./usuarios";
import perfis from "./perfis";
import usuarioLogado from "./usuarioLogado";
import pessoas from "./pessoas/pessoas";
import diagnosticos from "./diagnosticos";
import contas from "./financeiros/contas";
import formasPagamento from "./financeiros/formasPagamento";
import contasReceber from "./financeiros/contasReceber";
import eventosConsultorio from "./agendas/eventosConsultorio";
import eventosPilates from "./agendas/eventosPilates";
import listaEspera from "./listasEspera";
import notasFiscais from "./pessoas/notasFiscais";
import config from "./config";

export default combineReducers({
    usuarios,
    perfis,
    usuarioLogado,
    pessoas,
    diagnosticos,
    contas,
    formasPagamento,
    contasReceber,
    eventosConsultorio,
    eventosPilates,
    notasFiscais,
    listaEspera,
    config
});
