import React, { useState, useEffect } from 'react'
import MainMenu from '../../components/MainMenu'
import * as Yup from "yup";
import {Button, Col, Row} from 'react-bootstrap';
import { AlertGeral, requisitarAPI } from '../../components/AlertGeral';
import { CardMensagem } from './cardMensagem'
import '../../Global/styleGeral.css'
import api from '../../services/api';
import Spinner from '../../components/Spiner';
import { useSelector } from "react-redux";
import { Formik, Form } from 'formik';
import { SelectComponent } from '../../components/Form/Select.js';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const MensagensUsuarios = ( history ) => {
    const [usuarios, setUsuarios] = useState([]);
    const [retorno, setRetorno] = useState({});
    const [loading, setLoading] = useState(true);
    const [dadosForm, setDadosForm] = useState({id_usuario_destino: '', mensagem: ""})    
    const [mensagens, setMensagens] = useState([]);
    
    const usuarioLogado = useSelector(state => state.usuarioLogado);

    async function getUsuarios() {
        let usuariosAPI = await requisitarAPI(() => api.get('Usuarios'), 'Falha ao obter dados do servidor')

        if (!usuariosAPI.erro) {
            let usuarios = [];

            usuariosAPI.response.data.forEach(usuario => {
                if (usuario.id !== usuarioLogado.id)
                    usuarios.push({ label: usuario.nome, value: usuario.id })
            });

            setUsuarios(usuarios);
        }
    }

    async function getDados() {
        let mensagensAPI = await requisitarAPI(() => api.get('mensagensUsuarios/'+usuarioLogado.id), 'Falha ao obter dados do servidor')
        setRetorno(mensagensAPI);

        if (!mensagensAPI.erro) {
            setMensagens(mensagensAPI.response.data);
        }
    }


    useEffect(() => {
        getUsuarios();
        getDados();
        setLoading(false);
    },[dadosForm]);
    
    const schema = Yup.object().shape({
        id_usuario_destino: Yup.number().required("Informe o Usuário"),
        mensagem: Yup.string().required("Informe a Mensagem")
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        values.id_usuario_origem = usuarioLogado.id;

        const ret = await requisitarAPI(() => api.post('mensagensUsuarios', values), 'Falha ao cadastar mensagem');
        
        setLoading(false);
        setRetorno(ret);
        
        if (!ret.erro) {          
            ret.messageSuccess = "Mensagem enviada com SUCESSO!"
        }
        
    };

    return (
        <>
        <div>
            <MainMenu navigation={history} />

            {mensagens.length > 0 && (
                <>
                <div className="titulo-pagina"> Mensagens recebidas</div>
                <div className="container-principal">
                    {mensagens.map( item => <CardMensagem dados={item} mensagens={mensagens} setMensagens={setMensagens} setRetorno={setRetorno} setLoading={setLoading} />)}
                </div>
  </>          
            )}
            <div className="titulo-pagina"> Enviar Mensagem</div>
            <div className="container-principal">
                
                <Formik enableReinitialize initialValues={dadosForm} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
                    {({ errors }) => (
                    <Form>
                            <Col className="form-group">
                                <label className="obrigatorio">Usuário*</label>
                                <FieldValidate name="id_usuario_destino" component={SelectComponent} noClass options={usuarios} errors={errors} />
                            </Col>

                            <Col className="form-group">
                                <label className="obrigatorio">Mensagem*</label>
                                <FieldValidate name="mensagem" placeholder="Digite a mensagem" className="form-control" component="textarea" type="text" errors={errors} />
                            </Col>

                            <AlertGeral retorno={retorno} />
                            <Spinner loading={loading} />
                            
                            <Row  className="form-group text-center">
                                <Col className="text-center">
                                    <Button variant="salvar" type="submit" >
                                        <FontAwesomeIcon className="icone" icon={faPaperPlane} /> Enviar Mensagem
                                    </Button>
                                </Col>
                            </Row>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
        </>
    );
}

export default MensagensUsuarios; 