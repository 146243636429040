import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addDiagnosticos: ["diagnosticos"],
  addDiagnostico: ["diagnostico"],
  editDiagnostico: ["id","diagnostico"],
  removeDiagnostico: ["id"],

  addEvolucao:["diagnostico", "evolucao"],
  editEvolucao:["diagnostico", "evolucao"],
  removeEvolucao:["id_diagnostico", "id_evolucao"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addDiagnosticos = (state = INITIAL_STATE, action) => action.diagnosticos;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.diagnostico
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.diagnostico;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) => state.filter(item => item.id !== action.id);

const addEvolucao = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.diagnostico.id){
      item.evolucoes.push(action.evolucao);
      return item;
    } else {
      return item;
    }
  })
);

const editEvolucao = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.diagnostico.id){
      item.evolucoes = item.evolucoes.map(evo => {
        if (evo.id == action.evolucao.id) {
          return action.evolucao;
        } else {
         return evo;
        }
      })

      return item;
    } else {
      return item;
    }
  })
);

const removerEvolucao = (state = INITIAL_STATE, action) => (
  state.map((item) => {
    if(item.id === action.id_diagnostico){
      item.evolucoes = item.evolucoes.filter(item => item.id !== action.id_evolucao);
      return item;
    } else {
      return item;
    }
  })
);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_DIAGNOSTICOS]: addDiagnosticos,
  [Types.ADD_DIAGNOSTICO]: add,
  [Types.EDIT_DIAGNOSTICO]: edit,
  [Types.REMOVE_DIAGNOSTICO]: remove,
  [Types.ADD_EVOLUCAO]: addEvolucao,
  [Types.EDIT_EVOLUCAO]: editEvolucao,
  [Types.REMOVE_EVOLUCAO]: removerEvolucao,
});
