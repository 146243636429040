import moment from 'moment';

export function dateToField(data) {
    if (!data)
        return;
    
    return new Date(data);
}    

export function dateToView(data) {
    return moment(data).format('DD/MM/YYYY')
}

export function dateTimeToView(data) {
    return moment(data).format('DD/MM/YYYY HH:mm')
}

export function timeToView(data) {
    return moment(data).format('HH:mm')
}

export function diaIntToString(data) {
    switch (data) {
        case 0:
            return "Domingo"
        case 1:
            return "Segunda-Feira"
        case 2:
            return "Terça-Feira"
        case 3:
            return "Quarta-Feira"
        case 4:
            return "Quinta-Feira"            
        case 5:
            return "Sexta-Feira"            
        case 6:
            return "Sabado"
        default:
            return "INVÁLIDO"            
      } 
}

export function formatMoeda (valor)  {
    if (typeof valor == "undefined") return 0;
    
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function calcularIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
    var d = new Date(),
        ano_atual = d.getFullYear(),
        mes_atual = d.getMonth() + 1,
        dia_atual = d.getDate(),
        quantos_anos = ano_atual - ano_aniversario;

    ano_aniversario = +ano_aniversario;
    mes_aniversario = +mes_aniversario;
    dia_aniversario = +dia_aniversario;

    if ((mes_atual < mes_aniversario) || (mes_atual == mes_aniversario) && (dia_atual < dia_aniversario)) {
        quantos_anos--;
    }

    let idade = 0;
    if (quantos_anos > 0)
        idade = quantos_anos;

    return idade;
}

export function MesesEntreDatas(data1, data2) {
    var months;
    months = (data2.getFullYear() - data1.getFullYear()) * 12;
    months -= data1.getMonth();
    months += data2.getMonth();
    return months <= 0 ? 0 : months;
}

export function DiasEntreDatas(data1, data2) {
    var inicio = moment(data1);
    var fim = moment(data2);

    // diferença entre as datas, em dias
    var dias = fim.diff(inicio, 'days');
    return dias;
}

