/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import "./Global/styleGeral.css";
import "./App.css";
import Routes from './routes/';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {store, persistor} from "./Store"


function App() {
  return (
    <div id="app"> 
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          
        </PersistGate>
      </Provider>      
    </div>
  )
}
export default App;