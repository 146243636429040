import React from 'react';
import {Alert} from 'react-bootstrap';
import { Redirect } from 'react-router-dom'
import api from '../../services/api';

async function refreshToken(refresh) {
    const ret = await requisitarAPI(() => api.get('auth/refresh/'+refresh),'Falha ao fazer refresh no token.');

    if (!ret.erro) {
        localStorage.setItem('APP_TOKEN', ret.response.data.token);
        localStorage.setItem('REFRESH_TOKEN', ret.response.data.refresh);
    }
    return ret;
};

export function AlertGeral ({retorno}) {
    if (retorno.statusCode === 401) {
        return <Redirect to='/login' />; 
    }

    if (retorno.erro) {        
        return ( 
            <Alert className="alerta" variant="danger">
               <Alert.Heading>{retorno.titleErro}</Alert.Heading>
                <p>
                  {retorno.descriptErro}
                </p>
            </Alert>
        ) 
    } else if (retorno.messageSuccess) {
        return(
            <Alert className="alerta"  variant="success">
                <p>{retorno.messageSuccess}</p>
            </Alert>
        )
    } else {
        return ("")
    }
}

export async function requisitarAPI(doAPI, titleErro) {
    api.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('APP_TOKEN');
    let retorno = {};
    
    try {
        const response = await doAPI();
        retorno.response = response;
    } catch (e) {
        retorno.erro = true;
        retorno.titleErro = titleErro;
        retorno.response = e.response;
        if ((typeof e.response !== "undefined") && (e.response.data.mensagem !== '')) {
            retorno.descriptErro = e.response.data.mensagem;
            retorno.statusCode = e.response.status;
        } else {
            retorno.descriptErro = e.toString();
        }
    }

    return retorno;
}