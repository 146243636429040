import React, {useState, useEffect} from 'react';
import { Button, Row, Alert, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import MainMenu from '../../components/MainMenu';
import '../../Global/styleGeral.css';
import { CardNotasFiscais } from './notasFiscaisCard.js';
import api from '../../services/api'
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import { Creators as NotaActions } from "../../Store/Controls/pessoas/notasFiscais";
import Spinner from '../../components/Spiner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const NotasFiscais = ({ history, match }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [pessoa, setPessoa] = useState({nome: ''});

  const notas = useSelector(state => state.notasFiscais);
  const dispatch = useDispatch();

  async function getPessoa() {    
    let ret = await requisitarAPI(() =>  api.get('pessoas/'+match.params.id_pessoa),'Falha ao obter dados do servidor')
    setRetorno(ret);
    if (!ret.erro) {
      setPessoa(ret.response.data);
    }

  }

  async function getDados() {
    let ret = await requisitarAPI(() =>  api.get('notas/'+match.params.id_pessoa),'Falha ao obter dados do servidor')
    
    setRetorno(ret);

    if (!ret.erro) {
      dispatch(NotaActions.addNotas(ret.response.data));
    }
  }

  useEffect(() => {
    setLoading(true);
    getPessoa();
    getDados();
    setLoading(false);
  },[]);

  return (
    <>
      <MainMenu navigation={history} />
     

      <div className="titulo-pagina">Notas Fiscais - <span style={{color:"green"}}>{pessoa.nome}</span>
          <Button variant="principal" onClick={()=>{history.push(`/pessoas/notasFiscais/cadastro/${match.params.id_pessoa}/`)}} alt="Cadastrar Nota Fiscal" title="Adicione uma nova pessoa"> <FontAwesomeIcon className="icone" icon={faPlus}/> Cadastrar Nota Fiscal</Button>  

          <Button variant="voltar" onClick={() => { history.push(`/pessoas/editar/${match.params.id_pessoa}`) }}>
            <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para Cadastro da Pessoas
          </Button>

          <AlertGeral retorno={retorno} />
          <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          {notas.length > 0 ?
            <CardNotasFiscais history={history} notas={notas}/>
          :
            <Col md={6} className="text-center"> <Alert variant="danger" style={{margin:"20px"}}> <strong> NENHUMA NOTA FISCAL REGISTRADA </strong></Alert></Col>}
        </Row>
      
      </div>
    </>
  )
}

export default NotasFiscais;