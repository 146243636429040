const translate = {
    allDay: 'Todos',
    previous: 'Anterior',
    next: 'Próximo',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'data',
    time: 'hora',
    event: 'Evento', // Or anything you want
    showMore: total => `+ ${total} Mais..`
  }

  export default translate;