import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addFormasPagamento: ["forma"],
  addFormaPagamento: ["forma"],
  editFormaPagamento: ["id","forma"],
  removeFormaPagamento: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addFormasPagamento = (state = INITIAL_STATE, action) => action.forma;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.forma
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.forma;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_FORMAS_PAGAMENTO]: addFormasPagamento,
  [Types.ADD_FORMA_PAGAMENTO]: add,
  [Types.EDIT_FORMA_PAGAMENTO]: edit,
  [Types.REMOVE_FORMA_PAGAMENTO]: remove
});
