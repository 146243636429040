import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addUsers: ["user"],
  addUser: ["user"],
  editUser: ["id","user"],
  removeUser: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addUsers = (state = INITIAL_STATE, action) => action.user;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.user
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.user;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_USERS]: addUsers,
  [Types.ADD_USER]: add,
  [Types.EDIT_USER]: edit,
  [Types.REMOVE_USER]: remove
});
