import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { Formik, Form,  } from 'formik';
import FieldValidate from '../../components/Form/FieldValidate.js';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import api from '../../services/api';
import {AlertGeral, requisitarAPI} from '../../components/AlertGeral';
import Spinner from '../../components/Spiner';

const ModalAlterarSenha = ({ show, setShow, usuarioLogado }) => {
  const [dadosForm, setDadosForm] = useState({senha_atual:'', nova_senha:''});
  const [retorno, setRetorno]     = useState({});
  const [loading, setLoading]     = useState(false);

  const schemaModal = Yup.object().shape({
    senha: Yup.string().required("Informe a senha")
  });

  const handleModal = async (values) => {
    setLoading(true);
    const ret = await requisitarAPI(() => api.put('usuarios/'+usuarioLogado.id,values),'Falha ao alterar senha');
    setRetorno(ret);

    if (!ret.erro) {
      setShow(false) 
    }
    setLoading(false);

  };
 
    return (
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
            <Col>
              <AlertGeral retorno={retorno} />
              <Spinner loading={loading} />
            </Col>

            <Formik enableReinitialize onSubmit={handleModal} initialValues={dadosForm} validationSchema={schemaModal} validateOnChange={false} validateOnBlur={false}>
            {({ errors }) => (
            <Form>
              
              <Modal.Header closeButton>
                <Modal.Title> Alterar Senha </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Row className="form-group">
                  <Col>
                      <label className="obrigatorio">Nova senha*</label>
                      <FieldValidate name="senha" type="password" errors={errors} />
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Col style={{ display: "flex" }}>
                  <Button variant="salvar" style={{ width: "100%" }} type="submit">
                    Salvar
                  </Button>            
                </Col>
              </Modal.Footer>
              
            </Form>
            )}
            </Formik>
        </Modal>  
    )
};

export default ModalAlterarSenha;